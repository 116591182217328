import AdminSetting from 'Components/AdminSetting'
import { ConsulateAppointmentContext } from 'Context/VisaPortal/ConsulateAppointmentContext'
import { ConsulateAppointmentTypes, TagType } from 'Context/VisaPortal/ConsulateAppointmentTypes'
import { useContext, useEffect, useState } from 'react'
import { useForm, useFormState } from 'react-hook-form'
import { FaEdit, FaSave, FaTrashAlt } from 'react-icons/fa'
import http from 'CommonJS/http'
import { AxiosResponse } from 'axios'
import { toast } from 'Components/Toast/toast'
import { FaX } from 'react-icons/fa6'
import ConfirmationModal from 'Components/ConfirmationModal'
import Loading from 'Components/Loading'
import { FormDataType } from 'Components/AdminSetting/BasicSettings/ConsulateAppointmentFormTypes'

export default function StudentInfoTag() {
  const [loading, setLoading] = useState<boolean>(false)
  const [saveLoading, setSaveLoading] = useState<boolean>(false)
  const { tags, loadTag, updateTags } = useContext(ConsulateAppointmentContext) as ConsulateAppointmentTypes
  const [isConfirmModalOpen, setConfirmModalOpen] = useState<boolean>(false)
  const [selectRowId, setSelectRowId] = useState<number | null>(null)
  const [editData, setEditData] = useState<TagType | null>(null)
  const [editError, setEditError] = useState<string | null>(null)

  useEffect(() => {
    const init = async () => {
      setLoading(true)
      if (tags?.length === 0) await loadTag()

      setLoading(false)
    }
    init()
  }, [])

  const newEntryDefaultValue: FormDataType = {
    name: ''
  }

  const {
    register,
    handleSubmit,
    reset,
    control,
    setError,
    formState: { errors }
  } = useForm<FormDataType>({ defaultValues: newEntryDefaultValue })

  const { dirtyFields } = useFormState({
    control
  })

  const handleCreateNew = async (formData: FormDataType) => {
    setSaveLoading(true)
    await http
      .fetch({ method: 'post', path: 'setting/studentInfoTag', data: { name: formData.name } })
      .then((response: void | AxiosResponse<{ tag: TagType }>) => {
        if (response && response.status === 200) {
          updateTags([...tags, response.data.tag])
          reset({ name: '' })
          toast('New Tag Created Successfully', 'success')
        }
        setSaveLoading(false)
      })
      .catch(({ response }) => {
        toast(response?.data.message ?? 'Internal server error.', 'error')
        setSaveLoading(false)
        const nameErrors = response?.data?.errors?.name
        if (nameErrors && nameErrors?.length > 0) {
          setError('name', { message: nameErrors[0] })
        }
      })
  }

  const handleDelete = async () => {
    if (!selectRowId) {
      toast('Something went wrong, please reload window and try again.', 'error')
      return
    }
    setSaveLoading(true)
    await http
      .fetch({ method: 'delete', path: `setting/studentInfoTag/${selectRowId}` })
      .then((response: void | AxiosResponse<{ success: boolean; message?: string }>) => {
        if (response && response.status === 200 && response.data.success) {
          const newTag = tags.filter((sI) => sI.id !== selectRowId)
          updateTags(newTag)
          toast(response.data?.message ?? 'Tag Deleted Successfully', 'success')
        }
        setSaveLoading(false)
        setConfirmModalOpen(false)
      })
      .catch(({ response }) => {
        toast(response?.data.message ?? 'Internal server error.', 'error')
        setSaveLoading(false)
      })
  }

  const handleEdit = async () => {
    if (!editData || !editData.id) {
      toast('Something went wrong, please reload window and try again.', 'error')
      return
    }
    if (!editData.name) {
      setEditError('Tag name is required')
      return
    }

    setSaveLoading(true)
    setEditError(null)
    await http
      .fetch({ method: 'put', path: `setting/studentInfoTag/${editData.id}`, data: editData })
      .then((response: void | AxiosResponse<{ success: boolean; message?: string }>) => {
        if (response && response.status === 200 && response.data.success) {
          const newTag = tags.map((cJ) => {
            if (cJ.id === editData.id) {
              cJ.name = editData.name
            }
            return cJ
          })
          updateTags(newTag)
          toast(response.data?.message ?? 'Tag Updated Successfully', 'success')
          setEditData(null)
        }
        setSaveLoading(false)
      })
      .catch(({ response }) => {
        toast(response?.data.message ?? 'Internal server error.', 'error')
        setSaveLoading(false)
        const nameErrors = response?.data?.errors?.name
        if (nameErrors && nameErrors?.length > 0) {
          setEditError(nameErrors[0])
        }
      })
  }
  return (
    <>
      {loading && <Loading />}
      <div className='flex w-full min-h-screen md:flex-wrap md:min-h-full lg:flex-col'>
        <AdminSetting defaultOpen='student_info_dashboard' />
        <div className='px-7 py-5 w-full lg:px-4 min-w-[calc(100%-15rem)]'>
          {saveLoading && <Loading />}
          <h1 className='text-3xl font-bold md:text-2xl'>Tag</h1>

          <div className='w-full gap-y-4 flex flex-col md:w-full overflow-x-auto mt-4'>
            <table className='table-auto lg:w-max' data-cy='default-table'>
              <thead>
                <tr>
                  <th className='w-1/6'>Id</th>
                  <th>Name</th>
                  <th className='w-1/3'>Action</th>
                </tr>
              </thead>
              <tbody>
                {!loading && (!tags || tags.length === 0) && (
                  <tr>
                    <td colSpan={3}>No Tag Found</td>
                  </tr>
                )}
                {loading &&
                  Array.from(Array(2)).map((_, index) => (
                    <tr key={`loaderStudentInfoTags_${index}`} className='animate-pulse cursor-progress'>
                      <td>
                        <span className='block w-5 h-6 bg-primary/20 rounded'></span>
                      </td>
                      <td>
                        <span className='block w-40 md:w-20 h-6 bg-primary/20 rounded'></span>
                      </td>
                      <td>
                        <div className='flex gap-3'>
                          <span className='flex gap-3 py-3 px-7 bg-primary/20 rounded items-center'>
                            <span className='block w-8 h-5 bg-primary/20 rounded'></span>
                            <span className='block w-4 h-5 bg-primary/20 rounded'></span>
                          </span>
                          <span className='flex gap-3 py-3 px-7 bg-primary/20 rounded items-center'>
                            <span className='block w-12 h-5 bg-primary/20 rounded'></span>
                            <span className='block w-4 h-5 bg-primary/20 rounded'></span>
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                {tags?.map((tag, index) => (
                  <tr key={`studentInfoTag_${tag.id}_${index}`}>
                    <td>{tag.id}</td>
                    <td>
                      {editData && editData.id && editData.id === tag.id ? (
                        <div className='w-full max-w-[60%]'>
                          <input
                            type='text'
                            className={`form-input`}
                            placeholder={`Enter Tag`}
                            name={'name'}
                            value={editData.name}
                            onChange={(e) => {
                              const value = e.target.value as string
                              setEditData(() => {
                                return {
                                  ...editData,
                                  name: value
                                }
                              })
                            }}
                          />
                          {editError && (
                            <div className='text-left text-red-500 text-sm' data-cy='error'>
                              {editError}
                            </div>
                          )}
                        </div>
                      ) : (
                        <span>{tag.name}</span>
                      )}
                    </td>
                    <td>
                      <div className='flex gap-3'>
                        {editData && editData.id && editData.id === tag.id ? (
                          <>
                            <button className='btn btn-primary flex items-center gap-x-3' data-cy='save-btn' onClick={handleEdit}>
                              Save <FaSave />
                            </button>
                            <button
                              data-cy='cancel-btn'
                              className='btn btn-secondary flex items-center gap-x-3'
                              onClick={() => {
                                setEditData(null)
                                setEditError(null)
                              }}
                            >
                              Cancel <FaX />
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              className='btn btn-primary flex items-center gap-x-3'
                              data-cy='edit-btn'
                              onClick={() => {
                                setEditData(tag)
                              }}
                            >
                              Edit <FaEdit />
                            </button>
                            <button
                              data-cy='delete-btn'
                              className='btn btn-danger flex items-center gap-x-3'
                              onClick={() => {
                                setSelectRowId(tag.id)
                                setConfirmModalOpen(true)
                              }}
                            >
                              Delete <FaTrashAlt size={14} />
                            </button>
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr className='[&_td]:border-t [&_td]:border-t-black'>
                  <td colSpan={2}>
                    <div className='flex gap-3 items-center'>
                      <span className='font-semibold'>Create New Tag:</span>
                      <div className='w-full max-w-[60%]'>
                        <input
                          data-cy='name'
                          {...register('name', {
                            required: 'Tag name is required'
                          })}
                          type='text'
                          className={`form-input ${saveLoading ? 'cursor-progress' : ''}`}
                          placeholder={`Enter Tag`}
                          name={'name'}
                          disabled={saveLoading}
                        />
                        {errors?.name && (
                          <div className='text-left text-red-500 text-sm' data-cy='error'>
                            {errors.name.message}
                          </div>
                        )}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='flex gap-3'>
                      <button
                        className={`btn btn-primary flex items-center gap-x-3 ${saveLoading ? 'cursor-progress opacity-75' : ''} ${Object.keys(dirtyFields).length === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={saveLoading || Object.keys(dirtyFields).length === 0}
                        title={Object.keys(dirtyFields).length === 0 ? 'Enter name to create new activities attended.' : ''}
                        data-cy='save-btn'
                        onClick={handleSubmit(handleCreateNew)}
                      >
                        Save <FaSave />
                      </button>
                      <button
                        className={`btn btn-secondary flex items-center gap-x-3 ${saveLoading ? 'cursor-progress opacity-75' : ''} ${saveLoading || Object.keys(dirtyFields).length === 0 ? 'cursor-not-allowed opacity-50' : ''}`}
                        disabled={saveLoading || Object.keys(dirtyFields).length === 0}
                        data-cy='cancel-btn'
                        onClick={() => {
                          if (!saveLoading || Object.keys(dirtyFields).length !== 0) {
                            reset({ name: '' })
                          }
                        }}
                      >
                        Cancel <FaX />
                      </button>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <ConfirmationModal
            title='Are you sure you want to delete?'
            isConfirmModalOpen={isConfirmModalOpen}
            setConfirmModalOpen={setConfirmModalOpen}
            onAction={handleDelete}
          />
        </div>
      </div>
    </>
  )
}
