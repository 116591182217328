import { Autocomplete, createFilterOptions, TextField } from '@mui/material'
import { getMimeType, uploadFile } from 'CommonJS/common'
import http from 'CommonJS/http'
import FileUpload from 'Components/FileUpload'
import Loading from 'Components/Loading'
import { toast } from 'Components/Toast/toast'
import { useEffect, useState } from 'react'
import { Control, Controller } from 'react-hook-form'

interface Props {
  control: Control
  field: any
  setValue: any
}
const EditFields = ({ control, field, setValue }: Props) => {
  const [isLoading, setLoading] = useState<boolean>(false)
  const [attended, setAttended] = useState<Array<any>>([])

  useEffect(() => {
    if (field?.fieldName === 'activities_attended') getAttendedList()
  }, [field])

  function getAttendedList() {
    setLoading(true)
    http
      .fetch({ path: `/setting/activitiesAttended` })
      .then((res) => {
        if (res.data.success) setAttended(res.data.activitiesAttendeds)
        setLoading(false)
      })
      .catch((error) => {
        toast(error?.message, 'error')
        setLoading(false)
      })
  }

  const changeFileState = async (f: File) => {
    try {
      await setLoading(true)
      const res = await uploadFile(f)
      if (res) await setValue(field?.fieldName, res?.document?.fullPath)
      await setLoading(false)
    } catch (error) {
      setValue(field?.fieldName, '')
      setLoading(false)
    }
  }

  return (
    <>
      <Controller
        control={control}
        name={field?.fieldName}
        // rules={field?.isEditable && { required: { value: field?.isEditable, message: 'This field is required.' } }}
        render={({ field: { ref, value, onChange }, fieldState: { error } }) => {
          let types: string[] | undefined = []
          if (field?.fileType) {
            const extensions: string[] = field?.fileType?.split(',')
            types = extensions
              ?.map((i) => {
                return getMimeType(i)
              })
              .filter((n) => n)
          }
          return (
            <div className='flex-1 min-w-60'>
              {field?.type === 'text' && (
                <input ref={ref} value={value ?? ''} onChange={onChange} className='form-input' type='text' disabled={!field?.isEditable} />
              )}
              {field?.type === 'select' && (
                <>
                  <Autocomplete
                    id='activities-attended'
                    className='dashboard-icon-home-college w-full pt-2'
                    options={attended}
                    multiple
                    isOptionEqualToValue={(option, value) => option.id === value?.id}
                    getOptionLabel={(option: any) => option?.name}
                    defaultValue={value && typeof value === 'string' && value?.includes('[') ? JSON.parse(value) : []}
                    onChange={(event, selectedOptions) => {
                      setValue(field?.fieldName, selectedOptions)
                    }}
                    filterOptions={createFilterOptions({
                      limit: 100,
                      trim: true
                    })}
                    renderInput={(params) => <TextField {...params} label='Activities Attended' />}
                  />
                  {isLoading && <Loading />}
                </>
              )}
              {field?.type === 'upload' && (
                <div className='relative w-max'>
                  <FileUpload
                    size={50}
                    changeFileState={changeFileState}
                    fileUrl={value ? value : ''}
                    acceptedTypes={types?.length > 0 ? types : undefined}
                    isViewOnly={!field?.isEditable}
                  />
                  {isLoading && (
                    <div className=' flex justify-center items-center absolute top-0 right-0 left-0 bottom-0'>
                      <div className='spinner w-5 h-5 ' />
                    </div>
                  )}
                </div>
              )}
              {error && <p className='text-left text-red-500 text-sm'>{error?.message}</p>}
            </div>
          )
        }}
      />
    </>
  )
}

export default EditFields
