import Label from '../Label'
import { DetailPropsType } from '../type'
import { useContext, useEffect, useState } from 'react'
import { subStepContext } from '../context'
import { Controller } from 'react-hook-form'
import { AppContext } from 'Context/AppContext'
import { AppContextType } from 'Context/AppTypes'
import FileUpload from 'Components/FileUpload'
import Description from '../Description'
import { getMimeType } from 'CommonJS/common'

function FileUploadType({ item, control, isRenderConditionalComponent, setValue, studentId, isViewOnly }: DetailPropsType) {
  const {
    state: { currentPage }
  } = useContext(subStepContext)
  const { uploadFile } = useContext(AppContext) as AppContextType
  const [isLoading, setLoading] = useState<boolean>(false)
  const [acceptedTypes, setAcceptedTypes] = useState<string[]>([])

  useEffect(() => {
    setAcceptedExtension()
  }, [item])

  async function setAcceptedExtension() {
    const extensions: string[] = await item?.allowedExtensions?.split(', ')
    const types = await extensions
      ?.map((i) => {
        return getMimeType(i)
      })
      .filter((n) => n)

    // #344 - Enable all image file type for all fil uploads
    types.push('image/*')
    await setAcceptedTypes(types)
  }

  const changeFileState = async (f: File) => {
    try {
      await setLoading(true)
      const res = await uploadFile(f, studentId)
      if (res) await setValue(item?.id.toString(), res?.document?.fullPath)
      await setLoading(false)
    } catch (error) {
      setValue(item?.id.toString(), '')
      setLoading(false)
    }
  }

  if (isRenderConditionalComponent === false || currentPage !== item?.pageNumber || item?.visibility === 'hidden') return null

  return (
    <div className='mt-2'>
      <div className='flex justify-start md:flex-col'>
        <Label structure={item} />
        <Controller
          control={control}
          name={item?.id?.toString()}
          rules={isViewOnly ? {} : { required: { value: item?.isRequired, message: 'This field is required.' } }}
          render={({ field: { value }, fieldState: { error } }) => {
            return (
              <div>
                <div className='relative w-max'>
                  <FileUpload
                    size={85}
                    changeFileState={changeFileState}
                    fileUrl={value ? value : ''}
                    acceptedTypes={acceptedTypes?.length > 0 ? acceptedTypes : undefined}
                    isViewOnly={isViewOnly}
                  />
                  {isLoading && (
                    <div className=' flex justify-center items-center absolute top-0 right-0 left-0 bottom-0'>
                      <div className='spinner w-5 h-5 ' />
                    </div>
                  )}
                  {error && <p className='text-left text-red-500 text-sm'>{error?.message}</p>}
                </div>
                <Description structure={item} />
              </div>
            )
          }}
        />
      </div>
    </div>
  )
}

export default FileUploadType
