import http from 'CommonJS/http'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { FaSave } from 'react-icons/fa'
import { useContext, useEffect, useState } from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { removeBlankPairs } from 'CommonJS/common'
import AdminSetting from 'Components/AdminSetting'
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import Select from '@mui/material/Select'
import { Autocomplete, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, TextField, createFilterOptions } from '@mui/material'
import OutlinedInput from '@mui/material/OutlinedInput'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { useForm } from 'react-hook-form'
import Loading from 'Components/Loading'
import { AppContext } from 'Context/AppContext'
import { AppContextType } from 'Context/AppTypes'
import { DataItem, FormDataFields, LoginErrorType } from './type'
import { toast } from 'Components/Toast/toast'
import HomeCollegeHelp from 'Components/DashboardIcon/HomeCollegeHelp'

export default function EmergencyBannerAddEdit() {
  dayjs.extend(utc)
  dayjs.extend(timezone)
  const [error, setError] = useState<LoginErrorType>({})

  const navigate = useNavigate()
  const location = useLocation()
  const pathname = location.pathname
  const hasView = pathname.includes('view')
  const { id } = useParams()
  const [startDateOpen, setStartDateOpen] = useState<boolean>(false)
  const [endDateOpen, setEndDateOpen] = useState<boolean>(false)

  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
      }
    }
  }

  const [data, setData] = useState<Partial<DataItem>>()

  const defaultValues: FormDataFields = {
    endDatetime: null,
    startDatetime: null,
    bannerText: '',
    instituteIds: [],
    sessionIds: [],
    programIds: [],
    studentIds: [],
    yearIds: [],
    sendingInstitution: [],
    homeCollege: []
  }

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
    setValue
  } = useForm<FormDataFields>({ defaultValues })

  const [selected_institute, setSelectedInstitute] = useState<number[]>([])
  const [selected_session, setSelectedSession] = useState<number[]>([])
  const [selected_program, setSelectedProgram] = useState<number[]>([])
  const [selectedStudentId, setSelectedStudent] = useState<number[]>([])
  const [selected_year, setSelectedYear] = useState<number[]>([])
  const [selected_sending_institution, setSelectedSendingInstitution] = useState<string[]>([])
  const [selected_home_college, setSelectedHomeCollege] = useState<number[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const {
    institutes,
    years,
    sessions,
    programs,
    sendingInstitutions,
    homeColleges,
    getInstitute,
    getPrograms,
    getSession,
    getYears,
    getSendingInstitution,
    getHomeCollege
  } = useContext(AppContext) as AppContextType

  useEffect(() => {
    if (id) getIndividualBanner()
    if (institutes?.length === 0) getInstitute()
    if (programs?.length === 0) getPrograms()
    if (sessions?.length === 0) getSession()
    if (years?.length === 0) getYears()
    if (sendingInstitutions?.length === 0) getSendingInstitution()
    if (homeColleges?.length === 0) getHomeCollege()
  }, [])

  const getIndividualBanner = async () => {
    setLoading(true)
    await http
      .fetch({ path: `banners/${id}` })
      .catch(() => {
        toast('Internal Server Error.', 'error')
      })
      .then((response: any) => {
        setLoading(false)
        if (response && response.status === 200) {
          reset({
            bannerText: response?.data?.banner?.bannerText,
            startDatetime: dayjs.utc(response?.data?.banner?.startDatetime).local(),
            endDatetime: dayjs.utc(response?.data?.banner?.endDatetime).local(),
            instituteIds: response?.data?.banner?.instituteIds,
            sessionIds: response?.data?.banner?.sessionIds,
            programIds: response?.data?.banner?.programIds,
            studentIds: response?.data?.banner?.studentIds,
            yearIds: response?.data?.banner?.yearIds ?? [],
            sendingInstitution: response?.data?.banner?.sendingInstitution ?? [],
            homeCollege: response?.data?.banner?.homeCollege ?? []
          })
          setSelectedInstitute(response?.data?.banner?.instituteIds)
          setSelectedSession(response?.data?.banner?.sessionIds)
          setSelectedProgram(response?.data?.banner?.programIds)
          setSelectedStudent(response?.data?.banner?.studentIds)
          setSelectedYear(response?.data?.banner?.yearIds)
          setSelectedSendingInstitution(response?.data?.banner?.sendingInstitution)
          setSelectedHomeCollege(response?.data?.banner?.homeCollege)
        }
      })
  }

  const getStudent = async () => {
    await setLoading(true)
    const searchData = {
      institute_ids: getValues('instituteIds').filter((value) => value !== 0),
      session_ids: getValues('sessionIds').filter((value) => value !== 0),
      program_ids: getValues('programIds').filter((value) => value !== 0),
      year_ids: getValues('yearIds').filter((value) => value !== 0),
      sendingInstitution: getValues('sendingInstitution'),
      homeCollege: getValues('homeCollege').filter((value) => value !== 0)
    }

    await http
      .fetch({ method: `post`, path: 'setting/students', data: searchData })
      .catch(() => {
        toast('Internal server error.', 'error')
        setLoading(false)
      })
      .then((response) => {
        setLoading(false)
        if (response && response.status === 200) {
          setData((prevData) => ({
            ...prevData,
            students: response.data.students
          }))
        }
      })
    await setLoading(false)
  }

  useEffect(() => {
    if (
      getValues('instituteIds').length > 0 ||
      getValues('sessionIds').length > 0 ||
      getValues('programIds').length > 0 ||
      getValues('sendingInstitution').length > 0 ||
      getValues('homeCollege').length > 0 ||
      getValues('yearIds').length > 0
    ) {
      getStudent()
    }
  }, [
    getValues('instituteIds'),
    getValues('sessionIds'),
    getValues('programIds'),
    getValues('yearIds'),
    getValues('sendingInstitution'),
    getValues('homeCollege')
  ])

  const handleSave = async (info: FormDataFields) => {
    await setLoading(true)
    const newData = {
      banner_text: info?.bannerText,
      start_datetime: dayjs.utc(info?.startDatetime),
      end_datetime: dayjs.utc(info?.endDatetime),
      institute_ids: info?.instituteIds,
      session_ids: info?.sessionIds,
      program_ids: info?.programIds,
      student_ids: selectedStudentId,
      year_ids: info?.yearIds,
      sendingInstitution: info?.sendingInstitution,
      homeCollege: info?.homeCollege
    }
    const method = id ? 'put' : 'post'
    const path = id ? `/banners/${id}` : `/banners`
    await http
      .fetch({ method: method, path: path, data: removeBlankPairs(newData) })
      .catch((error) => {
        setError({
          message: error?.response?.data?.message || error.message,
          ...(error?.response?.data?.errors || [])
        })
        setLoading(false)
      })
      .then((response: any) => {
        setLoading(false)
        if (response && response.status === 200) {
          navigate('/admin/setting/emergency-banner')
          toast(response?.data?.message || 'Successfully added data', 'success')
        }
      })
    await setLoading(false)
  }

  const [selectAll, setSelectAll] = useState<boolean>(false)

  const handleCheckAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectAll(event.target.checked)
    setSelectedStudent(event.target.checked ? data?.students?.map((student) => student.id) || [] : [])
  }

  const handleCheckSingle = (studentId: number) => {
    setSelectedStudent((prevSelected) => {
      if (prevSelected.includes(studentId)) {
        return prevSelected.filter((id) => id !== studentId)
      } else {
        return [...prevSelected, studentId]
      }
    })
  }

  useEffect(() => {
    if (data?.students?.length === selectedStudentId.length) {
      setSelectAll(true)
    } else {
      setSelectAll(false)
    }
  }, [selectedStudentId, data?.students])

  const filterOptions = createFilterOptions({
    limit: 100,
    trim: true
  })
  return (
    <div className='flex w-full min-h-screen md:flex-wrap md:min-h-full lg:flex-col'>
      {loading && <Loading />}
      <AdminSetting defaultOpen='settings' />
      <div className='m-10 px-4 w-full lg:m-0 lg:px-4 lg:py-7' data-cy='emergency-banner-add-edit-page'>
        <div className='flex justify-between items-center'>
          <h1 className='text-3xl font-bold' data-cy='title'>
            Banner Notice
          </h1>
          <Link to='/admin/setting/emergency-banner'>
            <button className='btn-primary flex items-center gap-x-2' data-cy='go-back'>
              <IoIosArrowBack />
              Back
            </button>
          </Link>
        </div>
        <form
          className='p-7 md:p-4 rounded-md border border-slate-200 shadow-2xl shadow-primary/20	 mt-10'
          onSubmit={handleSubmit(handleSave)}
        >
          <div className='mb-6 md:mb-0'>
            <div className='mb-6 md:mb-0'>
              <label className='block uppercase tracking-wide text-gray-700 text-base font-semibold mb-2' htmlFor='grid-first-name'>
                Title
              </label>
              <input
                className='form-input'
                type='text'
                {...register('bannerText', {
                  required: true
                })}
                name='bannerText'
                data-cy='title'
                placeholder='Enter Banner Text'
              />
              {errors?.bannerText?.type == 'required' && (
                <p className='text-left text-red-500 text-sm' data-cy='error'>
                  Banner text is required
                </p>
              )}
            </div>
            <div className='mb-6 md:mb-0' data-cy='start-date'>
              <label className='block uppercase tracking-wide text-gray-700 text-base font-semibold mb-2' htmlFor='grid-first-name'>
                Start Date
              </label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DesktopDateTimePicker']}>
                  <DesktopDateTimePicker
                    label='Start DateTime'
                    value={getValues('startDatetime')}
                    {...register('startDatetime', {
                      required: true
                    })}
                    onChange={(newvalue: any) => {
                      setValue('startDatetime', newvalue)
                    }}
                    open={startDateOpen}
                    onClose={() => setStartDateOpen(false)}
                    slotProps={{
                      textField: {
                        onClick: () => setStartDateOpen(true)
                      }
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
              {errors?.startDatetime?.type == 'required' && (
                <p className='text-left text-red-500 text-sm' data-cy='error'>
                  Start date and time is required
                </p>
              )}
            </div>
            <div className='mb-6 md:mb-0' data-cy='end-date'>
              <label className='block uppercase tracking-wide text-gray-700 text-base font-semibold mb-2' htmlFor='grid-first-name'>
                End Date
              </label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DesktopDateTimePicker']}>
                  <DesktopDateTimePicker
                    label='End DateTime'
                    value={getValues('endDatetime')}
                    minDate={getValues('startDatetime')}
                    {...register('endDatetime', {
                      required: true
                    })}
                    onChange={(newvalue) => {
                      setValue('endDatetime', newvalue)
                    }}
                    open={endDateOpen}
                    onClose={() => setEndDateOpen(false)}
                    slotProps={{
                      textField: {
                        onClick: () => setEndDateOpen(true)
                      }
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
              {errors?.endDatetime?.type == 'required' && (
                <p className='text-left text-red-500 text-sm' data-cy='error'>
                  End date and time is required
                </p>
              )}
            </div>
            <div className='flex gap-3 w-full md:flex-wrap md:[&>*]:w-full md:[&>*]:!mx-0 flex-wrap items-center'>
              <FormControl sx={{ m: 1, minWidth: '160px' }}>
                <InputLabel id='emergency-banner-institutes-label'>Institutes</InputLabel>
                <Select
                  className='min-w-44 max-w-44 md:min-w-full md:max-w-full'
                  data-cy='institute'
                  labelId='emergency-banner-institutes-label'
                  id='emergency-banner-institutes'
                  multiple
                  value={getValues('instituteIds')}
                  {...register('instituteIds')}
                  onChange={(event) => {
                    const { value } = event.target
                    setValue('instituteIds', value?.length > 0 ? value.toString().split(',').map(Number) : [])
                    setSelectedInstitute(value?.length > 0 ? value.toString().split(',').map(Number) : [])
                  }}
                  input={<OutlinedInput label='Institutes' />}
                  renderValue={(selected) => {
                    const selectedNames = institutes
                      ?.filter((institute) => selected.includes(institute.id))
                      .map((institute) => institute.name)
                    return selectedNames ? selectedNames.join(', ') : ''
                  }}
                  MenuProps={MenuProps}
                >
                  {institutes?.map((institute) => {
                    return (
                      <MenuItem key={institute?.id} value={institute?.id}>
                        <Checkbox
                          checked={getValues('instituteIds')?.indexOf(institute?.id) > -1 && selected_institute.includes(institute?.id)}
                        />
                        <ListItemText primary={institute?.name} />
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: '160px' }}>
                <InputLabel id='emergency-banner-sessions-label'>Sessions</InputLabel>
                <Select
                  className='min-w-44 max-w-44 md:min-w-full md:max-w-full'
                  data-cy='sessions'
                  labelId='emergency-banner-sessions-label'
                  id='emergency-banner-sessions'
                  multiple
                  value={getValues('sessionIds')}
                  {...register('sessionIds')}
                  onChange={(event) => {
                    const { value } = event.target
                    setValue('sessionIds', value?.length > 0 ? value.toString().split(',').map(Number) : [])
                    setSelectedSession(value?.length > 0 ? value.toString().split(',').map(Number) : [])
                  }}
                  input={<OutlinedInput label='Sessions' />}
                  renderValue={(selected) => {
                    const selectedNames = sessions?.filter((session) => selected.includes(session.id)).map((session) => session.name)
                    return selectedNames ? selectedNames.join(', ') : ''
                  }}
                  MenuProps={MenuProps}
                >
                  {sessions?.map((indvidualsession) => (
                    <MenuItem key={indvidualsession?.id} value={indvidualsession?.id}>
                      <Checkbox
                        checked={
                          getValues('sessionIds').indexOf(indvidualsession?.id) > -1 && selected_session.includes(indvidualsession?.id)
                        }
                      />
                      <ListItemText primary={indvidualsession?.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: '160px' }}>
                <InputLabel id='emergency-banner-years-label'>Years</InputLabel>
                <Select
                  labelId='emergency-banner-years-label'
                  id='emergency-banner-years'
                  data-cy='years'
                  className='min-w-44 max-w-44 md:min-w-full md:max-w-full'
                  multiple
                  value={getValues('yearIds')}
                  {...register('yearIds')}
                  onChange={(event) => {
                    const { value } = event.target
                    setValue('yearIds', value?.length > 0 ? value.toString().split(',').map(Number) : [])
                    setSelectedYear(value?.length > 0 ? value.toString().split(',').map(Number) : [])
                  }}
                  input={<OutlinedInput label='years' />}
                  renderValue={(selected) => {
                    const selectedNames = years?.filter((year) => selected.includes(year.id)).map((year) => year.name)
                    return selectedNames ? selectedNames.join(', ') : ''
                  }}
                  placeholder='Select Year'
                  MenuProps={MenuProps}
                >
                  {years?.map((year) => (
                    <MenuItem key={year?.id} value={year?.id}>
                      <Checkbox checked={getValues('yearIds').indexOf(year?.id) > -1 && selected_year.includes(year?.id)} />
                      <ListItemText primary={year?.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: '160px' }}>
                <InputLabel id='emergency-banner-programs-label'>Programs</InputLabel>
                <Select
                  labelId='emergency-banner-programs-label'
                  id='emergency-banner-programs'
                  data-cy='programs'
                  className='min-w-44 max-w-44 md:min-w-full md:max-w-full'
                  multiple
                  value={getValues('programIds')}
                  {...register('programIds')}
                  onChange={(event) => {
                    const { value } = event.target
                    setValue('programIds', value?.length > 0 ? value.toString().split(',').map(Number) : [])
                    setSelectedProgram(value?.length > 0 ? value.toString().split(',').map(Number) : [])
                  }}
                  input={<OutlinedInput label='Programs' />}
                  renderValue={(selected) => {
                    const selectedNames = programs?.filter((program) => selected.includes(program.id)).map((program) => program.name)
                    return selectedNames ? selectedNames.join(', ') : ''
                  }}
                  placeholder='Select Session'
                  MenuProps={MenuProps}
                >
                  {programs?.map((program) => (
                    <MenuItem key={program?.id} value={program?.id}>
                      <Checkbox checked={getValues('programIds').indexOf(program?.id) > -1 && selected_program.includes(program?.id)} />
                      <ListItemText primary={program?.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: '160px' }}>
                <InputLabel id='emergency-banner-sending-institution-label'>Sending Institution</InputLabel>
                <Select
                  labelId='emergency-banner-sending-institution-label'
                  id='emergency-banner-sending-institution'
                  data-cy='sending-institution'
                  className='min-w-44 max-w-44 md:min-w-full md:max-w-full'
                  multiple
                  value={getValues('sendingInstitution')}
                  {...register('sendingInstitution')}
                  onChange={(event) => {
                    const { value } = event.target
                    setValue('sendingInstitution', Array.isArray(value) ? value : [])
                    setSelectedSendingInstitution(Array.isArray(value) ? value : [])
                  }}
                  input={<OutlinedInput label='Sending Institution' />}
                  renderValue={(selected) => {
                    const selectedNames = sendingInstitutions
                      ?.filter((sendingInstitution) => selected.includes(sendingInstitution.value))
                      .map((sendingInstitution) => sendingInstitution.text)
                    return selectedNames ? selectedNames.join(', ') : ''
                  }}
                  placeholder='Select Sending Institution'
                  MenuProps={MenuProps}
                >
                  {sendingInstitutions?.map((sendingInstitution) => (
                    <MenuItem key={sendingInstitution?.value} value={sendingInstitution?.value}>
                      <Checkbox
                        checked={
                          getValues('sendingInstitution').indexOf(sendingInstitution?.value) > -1 &&
                          selected_sending_institution.includes(sendingInstitution?.value)
                        }
                      />
                      <ListItemText primary={sendingInstitution?.text} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className='flex gap-3 items-center max-w-[53%] min-w-[41%] md:max-w-full md:min-w-full ml-2'>
                <Autocomplete
                  className='dashboard-icon-home-college w-full'
                  data-cy='home-university'
                  multiple
                  componentsProps={{ popper: { id: 'emergency-banner-home-college-popup' } }}
                  options={homeColleges}
                  {...register('homeCollege')}
                  getOptionLabel={(option: any) => option.name}
                  disableCloseOnSelect={true}
                  value={homeColleges.filter((option) => selected_home_college && selected_home_college.includes(option.id))}
                  onChange={(event, selectedOptions) => {
                    const selectedIds = Array.isArray(selectedOptions) ? selectedOptions.map((option: any) => option.id) : []
                    setValue('homeCollege', selectedIds)
                    setSelectedHomeCollege(selectedIds)
                  }}
                  filterOptions={filterOptions}
                  renderInput={(params) => <TextField {...params} label='Home University' />}
                />
                <HomeCollegeHelp />
              </div>
              {!hasView && (
                <div className='flex justify-center'>
                  <button type='submit' className='btn-primary flex items-center gap-x-3' data-cy='save-btn'>
                    Save <FaSave />
                  </button>
                </div>
              )}
            </div>
          </div>
          {error.message && (
            <div className='text-left text-red-500 text-sm' data-cy='error'>
              <p>{error?.message}</p>
            </div>
          )}
        </form>
        <div className='overflow-auto' id='student-data'>
          <table className='w-full lg:w-max'>
            <thead>
              <tr className='[&>*]:bg-primary [&>*]:text-white [&>*]:tracking-wider [&>*]:py-3 [&>*]:border-slate-500 [&>*]:text-center'>
                <th className='cursor-pointer'>
                  <Checkbox
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 18, color: '#ffffff' } }}
                    checked={selectAll}
                    onChange={handleCheckAll}
                  />
                </th>
                <th>FirstName</th>
                <th>LastName</th>
                <th>Session</th>
                <th>Academic Year</th>
                <th>Academic Program</th>
                <th>Host Institute</th>
                <th>Sending Institution</th>
                <th>Home University</th>
              </tr>
            </thead>
            <tbody>
              {data?.students?.length === 0 || !data?.students ? (
                <>
                  <tr>
                    <td colSpan={7}>No Student Found</td>
                  </tr>
                </>
              ) : (
                <>
                  {data?.students?.map((student) => (
                    <tr key={student.id}>
                      <td className='cursor-pointer text-center'>
                        <Checkbox
                          sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }}
                          checked={selectedStudentId.includes(student.id)}
                          onChange={() => handleCheckSingle(student.id)}
                        />
                      </td>
                      <td>{student.firstName}</td>
                      <td>{student.lastName}</td>
                      <td>{student?.session?.name ?? '-'}</td>
                      <td>{student?.year?.name ?? '-'}</td>
                      <td>{student?.program?.name ?? '-'}</td>
                      <td>{student?.institute?.name ?? '-'}</td>
                      <td>{student.sendingInstitution ?? '-'}</td>
                      <td>{student?.homeCollege?.name ?? '-'}</td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
