import http from 'CommonJS/http'
import { AxiosResponse } from 'axios'
import React, { createContext } from 'react'
import { toast } from 'Components/Toast/toast'
import {
  AltConsulateJurisdictionType,
  BatchVisaProcessType,
  ConsulateAppointmentTypes,
  ConsulateJurisdictionType,
  StatusInfoType,
  ActivitiesAttendedType,
  TagType
} from './ConsulateAppointmentTypes'

export const ConsulateAppointmentContext = createContext<ConsulateAppointmentTypes | null>(null)

const ConsulateAppointmentProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [consulateJurisdictions, setConsulateJurisdictions] = React.useState<ConsulateJurisdictionType[]>([])
  const [altConsulateJurisdictions, setAltConsulateJurisdictions] = React.useState<AltConsulateJurisdictionType[]>([])
  const [batchVisaProcesses, setBatchVisaProcesses] = React.useState<BatchVisaProcessType[]>([])
  const [statusInfos, setStatusInfos] = React.useState<StatusInfoType[]>([])
  const [activitiesAttendeds, setActivitiesAttendeds] = React.useState<ActivitiesAttendedType[]>([])
  const [tags, setTags] = React.useState<TagType[]>([])

  const loadConsulateJurisdiction = async () => {
    return http
      .fetch({ path: 'setting/consulateJurisdiction' })
      .then((response: void | AxiosResponse<{ consulateJurisdictions: ConsulateJurisdictionType[] }>) => {
        if (response && response.status === 200) {
          updateConsulateJurisdictions(response.data.consulateJurisdictions)
        }
      })
      .catch(({ response }) => {
        toast(response?.data.message ?? 'Internal server error.', 'error')
      })
  }

  const updateConsulateJurisdictions = (consulateJurisdiction: ConsulateJurisdictionType[]) => {
    setConsulateJurisdictions(consulateJurisdiction)
  }

  const loadAltConsulateJurisdiction = async () => {
    return http
      .fetch({ path: 'setting/altConsulateJurisdiction' })
      .then((response: void | AxiosResponse<{ altConsulateJurisdictions: AltConsulateJurisdictionType[] }>) => {
        if (response && response.status === 200) {
          updateAltConsulateJurisdictions(response.data.altConsulateJurisdictions)
        }
      })
      .catch(({ response }) => {
        toast(response?.data.message ?? 'Internal server error.', 'error')
      })
  }

  const updateAltConsulateJurisdictions = (altConsulateJurisdiction: AltConsulateJurisdictionType[]) => {
    setAltConsulateJurisdictions(altConsulateJurisdiction)
  }

  const loadBatchVisaProcesses = async () => {
    return http
      .fetch({ path: 'setting/batchVisaProcess' })
      .then((response: void | AxiosResponse<{ batchVisaProcesses: BatchVisaProcessType[] }>) => {
        if (response && response.status === 200) {
          updateBatchVisaProcesses(response.data.batchVisaProcesses)
        }
      })
      .catch(({ response }) => {
        toast(response?.data.message ?? 'Internal server error.', 'error')
      })
  }

  const updateBatchVisaProcesses = (batchVisaProcesses: BatchVisaProcessType[]) => {
    setBatchVisaProcesses(batchVisaProcesses)
  }

  const loadStatusInfo = async () => {
    return http
      .fetch({ path: 'setting/statusInfo' })
      .then((response: void | AxiosResponse<{ statusInfos: StatusInfoType[] }>) => {
        if (response && response.status === 200) {
          updateStatusInfos(response.data.statusInfos)
        }
      })
      .catch(({ response }) => {
        toast(response?.data.message ?? 'Internal server error.', 'error')
      })
  }

  const updateStatusInfos = (statusInfos: StatusInfoType[]) => {
    setStatusInfos(statusInfos)
  }

  const loadActivitiesAttended = async () => {
    return http
      .fetch({ path: 'setting/activitiesAttended' })
      .then((response: void | AxiosResponse<{ activitiesAttendeds: ActivitiesAttendedType[] }>) => {
        if (response && response.status === 200) {
          updateActivitiesAttendeds(response.data.activitiesAttendeds)
        }
      })
      .catch(({ response }) => {
        toast(response?.data.message ?? 'Internal server error.', 'error')
      })
  }

  const updateActivitiesAttendeds = (activitiesAttendeds: ActivitiesAttendedType[]) => {
    setActivitiesAttendeds(activitiesAttendeds)
  }

  const loadTag = async () => {
    return http
      .fetch({ path: 'setting/studentInfoTag' })
      .then((response: void | AxiosResponse<{ tags: TagType[] }>) => {
        if (response && response.status === 200) {
          updateTags(response.data.tags)
        }
      })
      .catch(({ response }) => {
        toast(response?.data.message ?? 'Internal server error.', 'error')
      })
  }

  const updateTags = (tags: TagType[]) => {
    setTags(tags)
  }

  return (
    <ConsulateAppointmentContext.Provider
      value={{
        consulateJurisdictions,
        loadConsulateJurisdiction,
        updateConsulateJurisdictions,
        altConsulateJurisdictions,
        loadAltConsulateJurisdiction,
        updateAltConsulateJurisdictions,
        batchVisaProcesses,
        loadBatchVisaProcesses,
        updateBatchVisaProcesses,
        statusInfos,
        loadStatusInfo,
        updateStatusInfos,
        activitiesAttendeds,
        loadActivitiesAttended,
        updateActivitiesAttendeds,
        tags,
        loadTag,
        updateTags
      }}
    >
      {children}
    </ConsulateAppointmentContext.Provider>
  )
}

export default ConsulateAppointmentProvider
