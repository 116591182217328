import http from 'CommonJS/http'
import { useState, useEffect } from 'react'
import Loading from 'Components/Loading'
import AdminSetting from 'Components/AdminSetting'
import { FaEdit, FaTrashAlt } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import ReactJsPagination from 'react-js-pagination'
import { toast } from 'Components/Toast/toast'
import ConfirmationAlert from 'Components/ConfirmationAlert'
import { DepartureCourse } from './type'
import { CiSquarePlus } from 'react-icons/ci'

export default function PreDepartureCourse() {
  const [departureCourses, setDepartureCourses] = useState<DepartureCourse>()
  const [loading, setLoading] = useState<boolean>(false)
  const [deleteDepartureCourseId, setDeleteDepartureCourseId] = useState<string>('')
  const [isConfirmAlertOpen, setConfirmAlertOpen] = useState<boolean>(false)

  useEffect(() => {
    getDepartureCourseData()
  }, [])

  const getDepartureCourseData = async (pageNumber = 1) => {
    await setLoading(true)
    await http
      .fetch({ path: `/setting/pre-departure-course?page=${pageNumber}` })
      .catch(() => {
        toast('Internal Server Error.', 'error')
        setLoading(false)
      })
      .then((response: any) => {
        setLoading(false)
        if (response && response.status === 200) {
          setDepartureCourses(response.data.departureCourses)
        }
      })
    await setLoading(false)
  }

  const handleDeleteDepartureCourse = async () => {
    if (!deleteDepartureCourseId) {
      toast('Something went wrong please try again', 'error')
      return
    }
    await setLoading(true)
    await http
      .fetch({ method: 'delete', path: `/setting/pre-departure-course/${deleteDepartureCourseId}` })
      .catch((error) => {
        toast(error?.data?.message ?? 'Internal server error', 'error')
        setLoading(false)
        setConfirmAlertOpen(false)
        setDeleteDepartureCourseId('')
      })
      .then((response: any) => {
        setLoading(false)
        if (response && response.status === 200) {
          toast(response?.data?.message || 'Successfully deleted insurance', 'success')
          getDepartureCourseData(1)
          setConfirmAlertOpen(false)
          setDeleteDepartureCourseId('')
        }
      })
    await setLoading(false)
  }
  return (
    <>
      {loading && <Loading />}
      <div className='flex w-full min-h-screen md:flex-wrap md:min-h-full lg:flex-col'>
        <AdminSetting defaultOpen='dashboard_icons' />
        <div className='px-7 py-5 w-full lg:px-4 min-w-[calc(100%-15rem)]' data-cy='pre-departure-course-page'>
          <div className='flex justify-between items-center gap-3 md:flex-wrap md:pb-4'>
            <h1 className='text-3xl font-bold decoration-1'>Pre Departure Course</h1>
            <Link
              to='/admin/setting/pre-departure-course/add'
              className='btn-primary bg-primary hover:bg-primary/90  text-white flex gap-x-2 items-center'
              data-cy='add-pre-departure-course-link'
            >
              Add Pre Departure Course
              <CiSquarePlus className='w-6 h-6' />
            </Link>
          </div>
          <div className='overflow-auto'>
            <table className='w-full mt-5 lg:w-max' data-cy='pre-departure-course-table'>
              <thead>
                <tr className='[&>*]:bg-primary [&>*]:text-white [&>*]:tracking-wider [&>*]:py-3 [&>*]:border-slate-500 [&>*]:text-left'>
                  <th>Id</th>
                  <th>Title</th>
                  <th>Institute</th>
                  <th>Session</th>
                  <th>Year</th>
                  <th>Program</th>
                  <th>Sending Institution</th>
                  <th>Home University</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {departureCourses?.data.length === 0 || !departureCourses ? (
                  <>
                    <tr>
                      <td colSpan={7}>No Pre Departure Course Found</td>
                    </tr>
                  </>
                ) : (
                  <>
                    {departureCourses?.data?.map((departureCourse) => (
                      <tr key={departureCourse.id}>
                        <td>{departureCourse?.id}</td>
                        <td>{departureCourse?.title ?? '-'}</td>
                        <td>{departureCourse?.institute.length > 0 ? departureCourse?.institute.join(', ') : '-'}</td>
                        <td>{departureCourse?.session.length > 0 ? departureCourse?.session.join(', ') : '-'}</td>
                        <td>{departureCourse?.year.length > 0 ? departureCourse?.year.join(', ') : '-'}</td>
                        <td>{departureCourse?.program.length > 0 ? departureCourse?.program.join(', ') : '-'}</td>
                        <td>{departureCourse?.sendingInstitution.length > 0 ? departureCourse?.sendingInstitution.join(', ') : '-'}</td>
                        <td>{departureCourse?.homeCollege.length > 0 ? departureCourse?.homeCollege.join(', ') : '-'}</td>
                        <td>
                          <div className='flex gap-5 items-center'>
                            <Link
                              to={`/admin/setting/pre-departure-course/edit/${departureCourse?.id}`}
                              className='btn btn-primary flex items-center gap-x-3'
                              data-cy='pre-departure-course-edit'
                            >
                              Edit <FaEdit />
                            </Link>
                            <button
                              className='btn btn-danger flex items-center gap-x-3'
                              data-cy='pre-departure-course-delete'
                              onClick={() => {
                                setDeleteDepartureCourseId(departureCourse?.id)
                                setConfirmAlertOpen(true)
                              }}
                            >
                              Delete <FaTrashAlt size={14} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
          </div>
          {Boolean(departureCourses?.data?.length ?? 0 > 0) && (
            <div className='mr-10 [&>ul]:flex [&>ul]:gap-3 [&>*_a]:py-2 [&>*_a]:px-4 [&>*_a]:lg:py-1 [&>*_a]:lg:px-3 [&>*_a]:inline-block mt-4 [&>*_a]:border [&>*_a:hover]:bg-primary [&>*_a:hover]:text-white [&>*_li.active>a]:bg-primary [&>*_li.active>a]:text-white'>
              <ReactJsPagination
                activePage={departureCourses?.currentPage || 1}
                itemsCountPerPage={departureCourses?.perPage}
                totalItemsCount={departureCourses?.total || 0}
                pageRangeDisplayed={departureCourses?.to}
                onChange={(pageNumber) => {
                  getDepartureCourseData(pageNumber)
                }}
              />
            </div>
          )}
        </div>
      </div>
      <ConfirmationAlert
        title='Are you sure you perform this action ?'
        isConfirmModalOpen={isConfirmAlertOpen}
        setConfirmModalOpen={(val: boolean) => {
          setConfirmAlertOpen(val)
          if (!val) setDeleteDepartureCourseId('')
        }}
        ButtonText={'Yes I am sure'}
        actionName='Delete pre departure course'
        onAction={handleDeleteDepartureCourse}
      />
    </>
  )
}
