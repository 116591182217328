import { FaAngleDown, FaAngleUp, FaArrowRight } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'
import { OpenAccordionType } from 'Components/AdminSetting/type'

interface DashboardIconPropsType {
  isOpen?: OpenAccordionType
  toggleSettings: (toggleBlock: OpenAccordionType) => void
}
export default function DashboardIcon({ isOpen, toggleSettings }: DashboardIconPropsType) {
  return (
    <>
      <h3
        className='font-semibold p-2 pl-5 cursor-pointer flex items-center justify-between'
        onClick={() => toggleSettings('dashboard_icons')}
      >
        DASHBOARD ICONS {isOpen ? <FaAngleUp /> : <FaAngleDown />}
      </h3>
      {isOpen === 'dashboard_icons' && (
        <>
          <li className='cursor-pointer [&>a]:!pl-9'>
            <NavLink to='/admin/setting/insurance-portal' className='flex justify-between items-center'>
              Insurance Portal
              <FaArrowRight className='hidden' />
            </NavLink>
          </li>
          <li className='[&>a]:!pl-9'>
            <NavLink to='/admin/setting/pre-departure-guide' className='flex justify-between items-center'>
              Pre Departure Guide
              <FaArrowRight className='hidden' />
            </NavLink>
          </li>
          <li className='[&>a]:!pl-9'>
            <NavLink to='/admin/setting/pre-departure-course' className='flex justify-between items-center'>
              Pre Departure Course
              <FaArrowRight className='hidden' />
            </NavLink>
          </li>
          <li className='[&>a]:!pl-9'>
            <NavLink to='/admin/setting/smartphone-app' className='flex justify-between items-center'>
              Smartphone App
              <FaArrowRight className='hidden' />
            </NavLink>
          </li>
          <li className='[&>a]:!pl-9'>
            <NavLink to='/admin/setting/course-schedule' className='flex justify-between items-center'>
              Course Schedule
              <FaArrowRight className='hidden' />
            </NavLink>
          </li>
        </>
      )}
    </>
  )
}
