export enum BulkActionsEnum {
  UnArchiveApplication = 'unArchiveApplication',
  AssignAdvisor = 'assign_advisor',
  MoveToApplication = 'application',
  MoveToCurrent = 'current',
  MoveToAlumni = 'alumni',
  WithdrawApplication = 'withdrawApplication',
  ArchiveApplication = 'archiveApplication',
  DeleteApplication = 'deleteApplication'
}

type BulkActionsRecordType = Record<BulkActionsEnum, string>
export interface BulkActionsType extends BulkActionsRecordType {}
