import './index.css'
import { FaDownload, FaPenToSquare } from 'react-icons/fa6'
import FileUpload from 'Components/FileUpload'
import { Chip } from '@mui/material'
import { Add } from '@mui/icons-material'
import CKEditors from 'Components/CKEditor'
import dayjs from 'dayjs'
import React from 'react'
interface InfoCardProps {
  info: any
  handleModalOpen: (data: any, incident?: any) => void
  updateForm: (data: any) => void
  isInsidentView?: boolean
}

export default function InfoCard({ info, handleModalOpen, updateForm, isInsidentView = false }: InfoCardProps) {
  const isAllDisable = info.fields?.every((i: any) => i.isEditable === false)

  const submitEditorData = (content: string, settingType: string) => {
    const payload = {
      [settingType]: content
    }
    updateForm(payload)
  }

  return (
    <div className={`flex flex-col gap-2 flex-1 w-auto mt-4 ${info?.fullWidth ? 'min-w-full' : 'min-w-96'}`}>
      <div className='flex items-center justify-between'>
        <h2 className='text-xl font-semibold'>{info.groupTitle}</h2>
        {!isAllDisable && info.groupTitle !== 'Incident Report' && info.groupTitle !== 'Incident Notes' && !info.fullWidth && (
          <FaPenToSquare className='cursor-pointer' onClick={() => handleModalOpen(info)} />
        )}
        {info.groupTitle === 'Incident Report' && (
          <button
            className='btn bg-secondary py-1 px-4 text-sm text-white rounded-md flex items-center gap-1 justify-center'
            onClick={() => handleModalOpen(info)}
          >
            Add Report
            <Add fontSize='small' />
          </button>
        )}
      </div>
      <div className='w-full p-3 md:p-4 rounded-md bg-white h-full'>
        <table className='w-full border-0 flex p-1 flex-col'>
          <tbody className={`w-full ${isInsidentView ? 'max-h-52 overflow-y-auto' : ''}`}>
            {info?.fields?.map((item: any, index: number) => (
              <React.Fragment key={index}>
                {item?.type === 'group' ? (
                  item?.fields?.map((field: any, _index: number) => {
                    return (
                      <tr key={_index} className='border-0 gap-2 flex md:gap-0'>
                        <td className='font-medium border-0 px-2 flex-1'>{field.label}</td>
                        <td className='border-0 px-2 font-light flex-1'>{field.metaValue ?? '-'}</td>
                      </tr>
                    )
                  })
                ) : item?.type === 'upload' ? (
                  <tr key={index} className='border-0 gap-2 flex md:gap-0'>
                    <td className='font-medium border-0 px-2 flex-1'>{item.label}</td>
                    <td className='border-0 px-2 font-light flex-1'>
                      <FileUpload isViewOnly={true} fileUrl={item.metaValue} size={35} />
                    </td>
                  </tr>
                ) : item?.type === 'select' ? (
                  <tr key={index} className='border-0 gap-2 flex md:gap-0'>
                    <td className='font-medium border-0 px-2 flex-1'>{item.label}</td>
                    <td className='font-medium border-0 px-2 gap-1 flex flex-wrap flex-1'>
                      {item.metaValue && typeof item.metaValue === 'string' && item.metaValue?.includes('[')
                        ? JSON.parse(item.metaValue)?.map((i: any, key: any) => {
                            return <Chip key={key} label={i?.name} sx={{ color: '#2d3e48' }} />
                          })
                        : ''}
                    </td>
                  </tr>
                ) : item?.type === 'ckeditor' ? (
                  <>
                    {item?.isEditable ? (
                      <tr key={index} className='border-0 gap-5 flex md:gap-0'>
                        <td className='border-0 px-2 w-full'>
                          <CKEditors
                            data-cy='tiny-text'
                            initialValue={item.metaValue}
                            type={item?.fieldName}
                            label={item.label}
                            callSubmit={submitEditorData}
                            isView={false}
                            variable={[]}
                            reSize={true}
                            classes={{
                              submitBtnBlock: '!border-0',
                              viewBlock: '!h-[30vh]',
                              editorBlock: 'h-auto'
                            }}
                          />
                        </td>
                      </tr>
                    ) : (
                      <tr key={index} className='border-0 gap-5 flex md:gap-0'>
                        <td className='font-medium border-0 px-2'>{item.label}</td>
                        <td className='border-0 px-2'>
                          <div dangerouslySetInnerHTML={{ __html: item.metaValue ?? '-' }} />
                        </td>
                      </tr>
                    )}
                  </>
                ) : item?.type === 'button' ? (
                  <tr key={index} className='border-0 gap-2 flex md:gap-0'>
                    <td className='font-medium border-0 px-2 flex-1'>{item.label}</td>
                    <td className='border-0 px-2 font-light flex-1'>
                      <div className='flex justify-center w-full'>
                        <FileUpload fileUrl={item?.metaValue} isViewOnly={true} size={35} />
                      </div>
                    </td>
                  </tr>
                ) : item?.type === 'form' ? (
                  <tr key={index} className='border-0 gap-2 flex md:gap-0'>
                    <td className='font-medium border-0 px-2 flex-1'>{item.label}</td>
                    <td className='border-0 px-2 font-light flex-1'>
                      <button className=' underline text-blue-500' onClick={() => handleModalOpen(info, item)}>
                        View
                      </button>
                    </td>
                  </tr>
                ) : isInsidentView ? (
                  <tr key={index} className='border-0 gap-2 flex md:gap-0'>
                    <td className='font-medium border-0 px-2 flex-1 flex justify-between'>
                      <label
                        className='text-blue-500 underline cursor-pointer'
                        onClick={() => handleModalOpen(info, item)}
                      >{`${item?.dateIncident ? dayjs(item?.dateIncident).format('MMM DD, YYYY') : ''} ${item?.incidentType} ${item?.titleIncident}`}</label>
                      <a href={item?.documentLink} download target='_blank' rel='noreferrer'>
                        <FaDownload className='cursor-pointer' />
                      </a>
                    </td>
                  </tr>
                ) : (
                  <tr key={index} className='border-0 gap-2 flex md:gap-0'>
                    <td className='font-medium border-0 px-2 flex-1'>{item.label}</td>
                    <td className='border-0 px-2 font-light flex-1'>
                      <div dangerouslySetInnerHTML={{ __html: item.metaValue ?? '-' }} />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
            {info?.fields?.length === 0 && isInsidentView && (
              <>
                <h1>No Incident reports</h1>
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}
