import { lazy, Suspense, useState } from 'react'
import ImagePlaceholder from 'assets/images/no-image-placeholder.png'
import { Link, useParams } from 'react-router-dom'
import ImageSelector from 'Components/ImageSelector'
import Loading from 'Components/Loading'
import { formatDate } from 'CommonJS/common'
import { Chip } from '@mui/material'
const StudentActivityLog = lazy(() => import('Components/Layout/SideMenu/StudentActivityLog'))

interface Props {
  personalInfo: any
}
export default function SideMenu({ personalInfo }: Props) {
  const { id } = useParams()
  const [openActivityLog, setOpenActivityLog] = useState<boolean>(false)

  const studentId = Number(id)

  return (
    <div className='text-center flex flex-col justify-between min-h-full max-h-full w-96 border-r lg:w-full relative h-[calc(100vh-77px)] lg:h-full'>
      <div className='overflow-y-auto overflow-x-hidden p-5 md:px-4'>
        {personalInfo?.fields?.map((item: any, index: number) => {
          return (
            <div key={index} className='flex justify-center items-center'>
              <div className='text-center'>
                {item?.fieldName === 'institute_image' ? (
                  <img src={item?.metaValue ?? ImagePlaceholder} alt='' className='w-full lg:w-1/2 lg:mx-auto md:lg:w-full h-[70px] mb-5' />
                ) : item?.fieldName === 'profile_image' ? (
                  <>
                    <ImageSelector
                      isViewOnly={!item?.isEditable}
                      size={'150px'}
                      imageSrc={item?.metaValue}
                      // changeFileState={changeFileState}
                      // changeImageSrcState={changeImageSrcState}
                    />
                    <h1 className='mt-5 font-bold text-base tracking-wide'>Personal Info</h1>
                    <div className=' h-1 mt-2 !bg-secondary !font-semibold mb-2' />
                  </>
                ) : item?.type === 'tag' ? (
                  <>
                    <div className='text-secondary uppercase visa-portal-font-bg mt-4'>{item?.label}</div>
                    <div className='flex gap-1 mt-1 mb-4 flex-wrap'>
                      {item?.metaValue?.map((tag: string, _key: any) => (
                        <Chip label={tag} key={_key} className='font-semibold' sx={{ color: '#2d3e48' }} />
                      ))}
                    </div>
                  </>
                ) : (
                  item?.metaValue && (
                    <>
                      {item?.fieldName !== 'full_name' && item?.fieldName !== 'nickname' && (
                        <div className='text-secondary uppercase visa-portal-font-bg mt-4'>{item?.label}</div>
                      )}
                      <h4 className='text-xl font-semibold text-primary'>
                        {item?.fieldName === 'birth_date' && item?.metaValue ? formatDate(item?.metaValue) : item?.metaValue}
                      </h4>
                    </>
                  )
                )}
              </div>
            </div>
          )
        })}
      </div>

      <div className='py-5 sticky bottom-0 bg-white  w-full left-0 right-0 px-5 shadow-[1px_-3px_10px_0px_#f2f2f2] lg:static lg:flex md:flex-wrap lg:gap-4 lg:[&>*]:w-auto lg:[&>*]:m-0 lg:items-center lg:justify-center md:[&>*]:min-w-60'>
        <Link className='inline-block w-full mb-3 btn-primary' to={`/admin/student-dashboard/${studentId}`}>
          Admission Dashboard
        </Link>
        <Link data-cy='visa-portal-dashboard' className='inline-block w-full mb-3 btn-primary' to={`/admin/visa-dashboard/${studentId}`}>
          Visa Dashboard
        </Link>
        <button
          className='w-full btn-secondary'
          onClick={() => {
            setOpenActivityLog(true)
          }}
        >
          Activity Log
        </button>
      </div>
      {openActivityLog && (
        <Suspense fallback={<Loading />}>
          <StudentActivityLog studentId={studentId} onClose={setOpenActivityLog} />
        </Suspense>
      )}
    </div>
  )
}
