import { FormSubStepSlugEnum } from './FormSubStepSlugEnum'
import { isStudent } from './roleHelper'

export function checkItemRenderLogic(
  field: any,
  formFields: any,
  slug: string,
  user: any,
  isViewOnly: boolean | undefined,
  watch: any
): { isFieldViewOnly: boolean; isRenderConditionalComponent: boolean } {
  let isRenderConditionalComponent = true
  const isFieldViewOnly = Boolean(
    (field?.gppaValuesEnabled === true &&
      field?.gppaValuesPrimaryProperty &&
      field?.gppaValuesTemplates &&
      Object.entries(field?.gppaValuesTemplates)?.length > 0 &&
      isStudent(user?.roles)) ||
      isViewOnly
  )

  if (field?.isiConditionalLogic?.sectionId) {
    const sectionItem = formFields?.find((section: any) => section?.id === field?.isiConditionalLogic?.sectionId)
    let sectionRender = false
    if (field?.pageNumber === sectionItem?.pageNumber) sectionRender = checkRenderLogic(sectionItem, formFields, watch, slug)
    else sectionRender = true

    if (sectionRender) {
      if (field?.isiConditionalLogic) {
        isRenderConditionalComponent = checkRenderLogic(field, formFields, watch, slug)
      } else isRenderConditionalComponent = true
    } else isRenderConditionalComponent = sectionRender
  } else {
    if (field?.isiConditionalLogic) {
      isRenderConditionalComponent = checkRenderLogic(field, formFields, watch, slug)
    } else isRenderConditionalComponent = true
  }

  return {
    isFieldViewOnly,
    isRenderConditionalComponent: isRenderConditionalComponent && field?.visibility !== 'hidden'
  }
}

function checkRenderLogic(item: any, formFields: any, watch: any, slug: string) {
  let currentRender = true
  let parentRender = true
  //check main current
  if (item?.isiConditionalLogic?.current) {
    const current = item?.isiConditionalLogic?.current
    if (current?.logicType === 'any') {
      currentRender = current?.groups?.some((group: any) => {
        return checkGroupsLogic(group, formFields, watch, slug)
      })
    } else if (current?.logicType === 'all') {
      currentRender = current?.groups?.every((group: any) => {
        return checkGroupsLogic(group, formFields, watch, slug)
      })
    }
  }

  //check main parent
  if (item?.isiConditionalLogic?.parent && item?.isiConditionalLogic?.parent?.length > 0) {
    const parents = item?.isiConditionalLogic?.parent
    let isCurrent = true
    let isParent = true

    //apply or operator for all parents
    parentRender = parents?.some((parent: any) => {
      //check current logic of main parent
      if (parent?.current?.logicType === 'any') {
        isCurrent = parent?.current?.groups?.some((group: any) => {
          return checkGroupsLogic(group, formFields, watch, slug)
        })
      } else if (parent?.current?.logicType === 'all') {
        isCurrent = parent?.current?.groups?.some((group: any) => {
          return checkGroupsLogic(group, formFields, watch, slug)
        })
      }

      //check parent logic of main parent
      if (parent?.parent?.logicType === 'any') {
        isParent = parent?.parent?.groups?.some((group: any) => {
          return checkGroupsLogic(group, formFields, watch, slug)
        })
      } else if (parent?.parent?.logicType === 'all') {
        isParent = parent?.parent?.groups?.some((group: any) => {
          return checkGroupsLogic(group, formFields, watch, slug)
        })
      }

      return isCurrent && isParent
    })
  }

  if (!currentRender && item?.isiConditionalLogic?.current?.actionType === 'hide') {
    return true
  } else if (currentRender && item?.isiConditionalLogic?.current?.actionType === 'hide') {
    return false
  } else return currentRender && parentRender
}

function checkGroupsLogic(group: any, formFields: any, watch: any, slug: string) {
  if (group?.logicType === 'all') {
    const isRender = group?.rules?.every((rule: any) => {
      return checkOperatorLogic(rule, formFields, watch, slug)
    })
    return isRender
  } else if (group?.logicType === 'any') {
    const isRender = group?.rules?.some((rule: any) => {
      return checkOperatorLogic(rule, formFields, watch, slug)
    })
    return isRender
  }
}

function checkOperatorLogic(rule: any, formFields: any, watch: any, slug: string) {
  const parentStep = formFields?.find((data: { id: any }) => data?.id == rule?.fieldId)
  const watchedValue = parentStep ? watch(parentStep?.id?.toString(), '') : ''
  if (slug === FormSubStepSlugEnum.INITIAL_REGISTRATION_FORM && String(rule.value).toLowerCase() === 'click here to begin') {
    // On initial registration form show all the field after click to begin by default.
    return true
  } else if (parentStep?.type === 'checkbox') {
    let values: string[] = []
    if (typeof watchedValue === 'string' && watchedValue !== '') {
      values = JSON.parse(watchedValue)
    } else {
      values = watchedValue
    }
    if (values) {
      if (rule?.operator === 'is') {
        return watchedValue?.includes(rule?.value)
      } else if (rule?.operator === 'isnot') {
        return !watchedValue?.includes(rule?.value)
      }
    } else return false
  } else if (rule?.operator === 'is') {
    return watchedValue === rule?.value
  } else if (rule?.operator === 'isnot') {
    return watchedValue !== rule?.value
  } else if (rule?.operator === '>') {
    return watchedValue > rule?.value
  } else if (rule?.operator === '<') {
    return watchedValue < rule?.value
  } else if (rule?.operator === 'contains') {
    return watchedValue?.includes(rule?.value)
  } else if (rule?.operator === 'starts_with') {
    return watchedValue?.startsWith(rule?.value)
  } else if (rule?.operator === 'ends_with') {
    return watchedValue?.endsWith(rule?.value)
  }
}
