import { DetailPropsType } from '../type'
import Label from '../Label'
import { Controller } from 'react-hook-form'
import { useContext, useEffect } from 'react'
import { subStepContext } from '../context'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'
import Description from '../Description'
import { TimePicker } from '@mui/x-date-pickers'
import { DisableTimeFields } from 'CommonJS/disableDateFields'

export default function TimeType({ item, control, isRenderConditionalComponent, isViewOnly, watch, setValue }: DetailPropsType) {
  const {
    state: { currentPage }
  } = useContext(subStepContext)

  useEffect(() => {
    if (item && DisableTimeFields.includes(item?.inputName?.toLowerCase())) {
      if (!watch(item?.id?.toString())) {
        setValue(item?.id?.toString(), dayjs().format('h:mm A'))
      }
    }
  }, [item])

  if (isRenderConditionalComponent === false || currentPage !== item?.pageNumber || item?.visibility === 'hidden') return null

  return (
    <div className='mt-2'>
      <div className='flex justify-start md:flex-col'>
        <Label structure={item} />
        <div className='flex flex-col w-[70%] gap-1'>
          <Controller
            control={control}
            name={item?.id?.toString()}
            rules={isViewOnly ? {} : { required: { value: item?.isRequired, message: 'This field is required.' } }}
            render={({ field: { ref, value, onChange }, fieldState: { error } }) => {
              return (
                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['TimePicker']}>
                      <TimePicker
                        ref={ref}
                        ampm={item?.timeFormat == '12'}
                        value={value ? dayjs(value, 'h:mm A') : null}
                        onChange={(newValue) => onChange(newValue ? newValue.format('h:mm A') : null)}
                        disabled={isViewOnly || DisableTimeFields.includes(item?.inputName?.toLowerCase())}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                  {error && <p className='text-left text-red-500 text-sm'>{error?.message}</p>}
                </div>
              )
            }}
          />
          <Description structure={item} />
        </div>
      </div>
    </div>
  )
}
