import { useEffect, useState } from 'react'
import { FormStep, FormSubStep } from './type'
import http from 'CommonJS/http'
import { toast } from 'Components/Toast/toast'
import Loading from 'Components/Loading'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { FaSave } from 'react-icons/fa'
import { MdCloudSync } from 'react-icons/md'
import { FormSubStepSlugEnum } from 'CommonJS/FormSubStepSlugEnum'
import { uploadFile } from 'CommonJS/common'

export default function StudentInfoPortal() {
  const [formData, setFormData] = useState<FormStep[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    getformStep()
  }, [])

  const getformStep = async () => {
    setLoading(true)
    http
      .fetch({ path: 'setting/formStep?globalSetting=global_settings' })
      .catch(() => {
        setLoading(false)
        toast('Internal Server Error.', 'error')
      })
      .then((response) => {
        setLoading(false)
        if (response && response.status === 200) {
          setFormData(response.data.formSteps)
        }
      })
  }

  const handleSave = async (e: React.FormEvent<HTMLFormElement>, subStep: FormSubStep, field: string) => {
    e.preventDefault()
    const data = new FormData(e.currentTarget)
    let response
    if (field == 'icon') {
      setLoading(true)
      const file = data.get(field)
      if (file instanceof File && file.type) {
        response = await uploadFile(file)
      }
    }
    if (response?.success || field != 'icon') {
      const iconUpload = response?.document.id
      const clickedButton = e.nativeEvent.submitter as HTMLButtonElement
      const payload = {
        [field]: field == 'icon' ? iconUpload : data.get(field),
        flag: clickedButton.name
      }
      setLoading(true)
      http
        .fetch({ method: 'post', path: `setting/formSubStep/${subStep.id}`, data: payload })
        .catch((error) => {
          setLoading(false)
          toast(error?.response?.data?.message, 'error')
        })
        .then((response) => {
          setLoading(false)
          if (response && response.status === 200) {
            getformStep()
            toast(response?.data?.message, 'success')
          }
        })
    }
  }

  return (
    <>
      {loading && <Loading />}
      <div className='w-full'>
        <div className='flex justify-between items-center min-h-12 mb-7 md:mb-5 '>
          <h1 className='text-3xl font-bold uppercase md:text-2xl'>Student Info Forms Settings</h1>
        </div>
        <div className=''>
          {formData.map((step, index) => (
            <div className='p-4 rounded-md border border-slate-200 shadow-md shadow-primary/10 mt-7 md:p-2' key={index}>
              <Accordion className='steps-list' defaultExpanded={index < 1}>
                <AccordionSummary className='step' expandIcon={<ExpandMoreIcon />} aria-controls='panel1-content' id='panel1-header'>
                  <h3 className='text-3xl font-bold lg:text-lg'> All Forms</h3>
                </AccordionSummary>
                <AccordionDetails className=''>
                  <div className='flex gap-4 items-center justify-end instructions step after:hidden lg:gap-2'></div>
                  <div className='overflow-x-auto'>
                    <table className='table-auto w-full md:table md:w-max'>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Institute</th>
                          <th>Gravity form</th>
                        </tr>
                      </thead>
                      <tbody>
                        {step.formSubSteps.map((subStep, index) => (
                          <tr key={`${subStep?.id ?? 'form_sub_step'}_${index}`}>
                            <td>
                              <label className='text-xl font-medium decoration-1 md:text-base'>{subStep?.name}</label>
                            </td>
                            <td>{subStep.instituteName ? subStep.instituteName : 'All'}</td>
                            {subStep?.type == 'form' && subStep.slug === FormSubStepSlugEnum.INCIDENT_FORM && (
                              <td>
                                <form
                                  className='flex gap-2 justify-end lg:flex-wrap lg:justify-center'
                                  key={index}
                                  data-cy={`${index}_sub_step_form`}
                                  onSubmit={(e) => handleSave(e, subStep, 'hubspotFormId')}
                                >
                                  <input
                                    name='hubspotFormId'
                                    defaultValue={subStep?.hubspotFormId || ''}
                                    type='number'
                                    className='form-input max-w-52 md:min-w-36 md:max-w-36 py-2 lg:max-w-28'
                                    placeholder='Gravity From Id'
                                    title='Type Gravity From Id'
                                    required={true}
                                  />
                                  <button className='btn-primary p-3' name='save' title='Save gravity form id'>
                                    <FaSave />
                                  </button>
                                  <button
                                    className='btn-primary p-3'
                                    name='sync'
                                    data-cy='save-and-sync-btn'
                                    title='Save & Sync gravity form structure with backend'
                                  >
                                    <MdCloudSync />
                                  </button>
                                </form>
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
