import http from 'CommonJS/http'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { FaEye, FaSave } from 'react-icons/fa'
import { useEffect, useRef, useState } from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { linkify, removeBlankPairs } from 'CommonJS/common'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { ClassicEditor } from 'ckeditor5'
import 'ckeditor5/ckeditor5.css'
import { Editor as CKEditorType } from '@ckeditor/ckeditor5-core'
import { CKEditorConfig } from 'Components/CKEditor/pluginAndTollbar'
// import { Editor } from '@tinymce/tinymce-react'
// import { Editor as TinyMCEEditor } from 'tinymce'
import AdminSetting from 'Components/AdminSetting'
import { Controller, useForm } from 'react-hook-form'
import Loading from 'Components/Loading'
import { toast } from 'Components/Toast/toast'
import Description from './Description'
import { TemplateData } from './type'
// import { TinyMcePlugins, TinyMceToolbar, TinyMceCustomizationOptions } from 'Components/TinyEditor/pluginAndTollbar'
import { FormControlLabel, Switch, Tooltip } from '@mui/material'
import { BsQuestionCircleFill } from 'react-icons/bs'
import { AxiosResponse } from 'axios'
import { EmailTemplateEnum } from 'CommonJS/EmailTemplateEnum'

export default function EmailTemplatesEdit() {
  interface Errors {
    subject?: string[]
    body?: string[]
  }
  const editorRef = useRef<CKEditorType | null>(null)
  const [error, setError] = useState<Errors>({})
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const pathname = location.pathname
  const hasView = pathname.includes('view')
  const templateId = searchParams.get('templateId')
  const [variable, setVariable] = useState<Array<string>>([''])
  const [loading, setLoading] = useState(false)
  const [showDescription, setShowDescription] = useState<TemplateData | null>(null)

  type Inputs = {
    id: string
    subject: string
    body: string
    key: string
    description: string
    isEnabled: boolean
    sendCopy: boolean
    inAppNotification: boolean
    rawKey: string
  }

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    getValues,
    setValue,
    control
  } = useForm<Inputs>({
    defaultValues: {
      isEnabled: false
    }
  })

  useEffect(() => {
    if (templateId !== undefined && templateId !== null) {
      setLoading(true)
      getAdvisorData()
      http
        .fetch({ path: '/templates/variables?type=email' })
        .catch(() => {
          toast('Internal server error.', 'error')
        })
        .then((response) => {
          setLoading(false)
          if (response && response.status === 200) {
            setVariable(response.data.variables)
          }
        })
    } else {
      reset({
        id: '',
        subject: '',
        body: '',
        key: ''
      })
    }
  }, [templateId])

  const getAdvisorData = async () => {
    await http
      .fetch({ path: `templates/${templateId}` })
      .catch(() => {
        toast('Internal Server Error.', 'error')
      })
      .then((response: void | AxiosResponse<{ template: Inputs }>) => {
        if (response && response.status === 200) {
          reset(response.data.template)
        }
      })
  }

  const handleSave = async (info: Inputs) => {
    await setLoading(true)
    const newData = info
    await http
      .fetch({ method: `put`, path: `/templates/${templateId}`, data: removeBlankPairs(newData) })
      .catch((error) => {
        setError(error.response.data.errors)
        setLoading(false)
      })
      .then((response: any) => {
        setLoading(false)
        if (response && response.status === 200) {
          navigate('/admin/setting/template')
          toast(response?.data?.message || 'Successfully added data', 'success')
        }
      })
    await setLoading(false)
  }

  async function handleDynamicVariable(item: string) {
    // editorRef?.current?.insertContent(item)
    if (editorRef.current) {
      const editor = editorRef?.current

      editor.model.change((writer) => {
        const selection = editor?.model?.document?.selection
        const insertPosition = selection?.getFirstPosition()
        if (insertPosition) {
          writer.insertText(item, insertPosition)
        }
      })
    }
  }

  // const apiKey = process.env.REACT_APP_TINYMCE_API_KEY ?? ''

  return (
    <>
      {loading && <Loading />}
      <div className='flex w-full min-h-screen md:flex-wrap md:min-h-full lg:flex-col'>
        <AdminSetting defaultOpen='settings' />
        <div className='m-10 px-4 w-full md:my-5 md:mx-0 lg:m-0 lg:py-7 lg:px-4' data-cy='email-template-add-edit-page'>
          <div className='flex justify-between items-center'>
            <h1 className='text-3xl font-bold' data-cy='title'>
              {hasView ? 'View Email Template' : 'Edit Email Template'}{' '}
            </h1>
            <Link to='/admin/setting/template'>
              <button className='btn-primary flex items-center gap-x-2' data-cy='go-back'>
                <IoIosArrowBack />
                Back
              </button>
            </Link>
          </div>
          <form
            className='p-7 md:p-4 rounded-md border border-slate-200 shadow-2xl shadow-primary/20	 mt-10'
            onSubmit={handleSubmit(handleSave)}
          >
            <div className='mb-6 md:mb-3'>
              <div className='mb-6 md:mb-3'>
                <label className='block uppercase tracking-wide text-gray-700 text-base font-semibold mb-2' htmlFor='grid-first-name'>
                  Key
                </label>
                <input
                  className='form-input'
                  {...register('key')}
                  type='text'
                  name='key'
                  placeholder='Enter Key'
                  data-cy='key'
                  disabled={true}
                />
              </div>
              <div className='mb-6 md:mb-3'>
                <label className='block uppercase tracking-wide text-gray-700 text-base font-semibold mb-2' htmlFor='grid-first-name'>
                  Description
                </label>
                <div className='w-max'>
                  <a
                    className='btn btn-secondary cursor-pointer flex items-center gap-x-3'
                    onClick={() => setShowDescription(getValues() as TemplateData)}
                  >
                    View <FaEye />
                  </a>
                </div>
              </div>
              <div className='mb-6 md:mb-3'>
                <div className='flex flex-row gap-2'>
                  <label className='block uppercase tracking-wide text-gray-700 text-base font-semibold mb-2' htmlFor='grid-first-name'>
                    Enabled
                  </label>
                  <Tooltip
                    title={
                      <>
                        <div className='font-semibold'>Enable/Disable Email notifications for this template</div>
                        <ul className='list-disc'>
                          <li>
                            <div className='font-semibold'>Enable:</div>Turn on to send email notifications.
                          </li>
                          <li>
                            <div className='font-semibold'>Disable:</div>Turn off to stop sending email notifications.
                          </li>
                        </ul>
                      </>
                    }
                    classes={{ popper: '[&_div]:text-base [&>div]:max-w-[450px]' }}
                    enterTouchDelay={1}
                    placement='bottom'
                    arrow
                  >
                    <span>
                      <BsQuestionCircleFill className='size-5 md:size-7 mt-1 text-slate-400 cursor-pointer' />
                    </span>
                  </Tooltip>
                </div>
                <Controller
                  name='isEnabled'
                  control={control}
                  defaultValue={false}
                  disabled={hasView}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Switch {...field} checked={Boolean(field.value)} inputProps={{ 'aria-label': 'controlled' }} />}
                      label=''
                    />
                  )}
                />
              </div>
              <div className='mb-6 md:mb-3'>
                <div className='flex flex-row gap-2'>
                  <label className='block uppercase tracking-wide text-gray-700 text-base font-semibold mb-2' htmlFor='grid-send-copy'>
                    Send copy to Advisor, Hubspot and Admission
                  </label>
                </div>
                <Controller
                  name='sendCopy'
                  control={control}
                  defaultValue={false}
                  disabled={hasView}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Switch {...field} checked={Boolean(field.value)} inputProps={{ 'aria-label': 'controlled' }} />}
                      label=''
                    />
                  )}
                />
              </div>
              {[
                EmailTemplateEnum.STUDENT_ACCEPTANCE_LETTER,
                EmailTemplateEnum.STUDENT_VISA_LETTER,
                EmailTemplateEnum.EXPORT_REPORT,
                EmailTemplateEnum.DOWNLOAD_FORM_SUBMISSION_PDF
              ].includes(watch('rawKey') as EmailTemplateEnum) && (
                <div className='mb-6 md:mb-3'>
                  <div className='flex flex-row gap-2'>
                    <label className='block uppercase tracking-wide text-gray-700 text-base font-semibold mb-2' htmlFor='grid-send-copy'>
                      In App Notification
                    </label>
                  </div>
                  <Controller
                    name='inAppNotification'
                    control={control}
                    disabled={hasView}
                    render={({ field }) => (
                      <FormControlLabel
                        control={<Switch {...field} checked={field.value} inputProps={{ 'aria-label': 'controlled' }} />}
                        label=''
                      />
                    )}
                  />
                </div>
              )}
              <div className='mb-6 md:mb-3'>
                <label className='block uppercase tracking-wide text-gray-700 text-base font-semibold mb-2' htmlFor='grid-first-name'>
                  Subject
                </label>
                <input
                  className='form-input'
                  {...register('subject', {
                    required: true
                  })}
                  type='text'
                  name='subject'
                  placeholder='Enter Subject'
                  disabled={hasView}
                  data-cy='name'
                />
                {errors?.subject?.type == 'required' && (
                  <p className='text-left text-red-500 text-sm' data-cy='error'>
                    Subject is required
                  </p>
                )}
                {error.subject && (
                  <p className='text-red-500 text-xs italic' data-cy='error'>
                    {error?.subject?.[0]}
                  </p>
                )}
              </div>
              <div className=''>
                <label className='block uppercase tracking-wide text-gray-700 text-base font-semibold mb-2' htmlFor='grid-last-name'>
                  Content
                </label>
                {hasView ? (
                  <iframe
                    className='w-full h-[50vh] border border-slate-200'
                    srcDoc={
                      getValues('body')
                        ? linkify(getValues('body'), { target: '_blank' }) +
                          "<style> body{font-family:'Helvetica Neue',sans-serif;}</style>"
                        : '<h3 style="color: rgb(31, 41, 55); font-size: 20px;">No Note Found</h3>'
                    }
                  ></iframe>
                ) : (
                  <div>
                    <input type='hidden' {...register('body', { required: true })} />
                    <CKEditor
                      editor={ClassicEditor}
                      data={getValues('body')}
                      onReady={(editor) => {
                        editorRef.current = editor
                      }}
                      disabled={hasView}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        setValue('body', data)
                      }}
                      config={CKEditorConfig}
                      data-cy='editor'
                    />
                  </div>
                  // <Editor
                  //   initialValue={getValues('body')}
                  //   onInit={(evt, editor) => (editorRef.current = editor)}
                  //   apiKey={apiKey}
                  //   disabled={hasView}
                  //   onEditorChange={(content) => {
                  //     setValue('body', content)
                  //   }}
                  //   {...register('body', {
                  //     required: true
                  //   })}
                  //   init={{
                  //     height: 500,
                  //     resize: 'both',
                  //     plugins: [...TinyMcePlugins],
                  //     toolbar: [...TinyMceToolbar],
                  //     ...TinyMceCustomizationOptions,
                  //     save_onsavecallback: (instance: any) => {
                  //       setValue('body', instance?.getContent())
                  //       handleSubmit(handleSave)()
                  //     }
                  //   }}
                  //   data-cy='editor'
                  // />
                )}
                {errors?.body?.type == 'required' && (
                  <p className='text-left text-red-500 text-sm' data-cy='error'>
                    Body is required
                  </p>
                )}
                {error.body && (
                  <p className='text-red-500 text-xs italic' data-cy='error'>
                    {error?.body?.[0] || 'The body field is required.'}
                  </p>
                )}
              </div>
            </div>
            {!hasView && (
              <>
                <div className='mt-10 flex flex-wrap gap-2 [&>_*]:select-none'>
                  {variable.length > 0 && (
                    <>
                      <b>DYNAMICS VARIABLES TO USE: </b>
                      {variable.map((item, i) => {
                        return (
                          <span className='badge-primary' key={i} onClick={() => handleDynamicVariable(item)}>
                            {item}
                          </span>
                        )
                      })}
                    </>
                  )}
                </div>
                <div className='flex justify-center mt-6'>
                  <button type='submit' className='btn-primary flex items-center gap-x-3' data-cy='save-btn'>
                    Save <FaSave />
                  </button>
                </div>
              </>
            )}
          </form>
        </div>
        {showDescription !== null && <Description template={showDescription} onClose={() => setShowDescription(null)} />}
      </div>
    </>
  )
}
