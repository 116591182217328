export const DisableDateFields = [
  'course_selection_date', // STEP ONE - Course Selection Form (Florence | Perugia)
  'date_housing', // STEP ONE - Housing Request Form
  'reviewed_both_date', // STEP ONE - Personal Information Form
  'commitment_agreement_date', // STEP TWO - Enrollment Commitment Form
  'health_date', // STEP TWO - Health Information Form
  'participation_date', // STEP TWO - Participation Agreements
  'course_schedule_confirmation_date', // Step Four: Course Schedule Confirmation
  'todays_date' //Incident form in student Info
]

export const DisableTimeFields = [
  'current_time' //Incident form in student Info
]
