import { Autocomplete, Chip, createFilterOptions, Modal, TextField } from '@mui/material'
import InfoCard from './InfoCard'
import SideMenu from './SideMenu'
import { useContext, useEffect, useState } from 'react'
import http from 'CommonJS/http'
import { useParams } from 'react-router-dom'
import { Close } from '@mui/icons-material'
import { Controller, useForm } from 'react-hook-form'
import Loading from 'Components/Loading'
import EditFields from './EditFields'
import { toast } from 'Components/Toast/toast'
import { FaPenToSquare } from 'react-icons/fa6'
import FormItem from 'Components/Dashboard/FormItem'
import { UserContext } from 'Context/UserContext'
import SubStepProvider, { subStepContext } from 'Components/Dashboard/context'
import { ActionType } from 'Components/Dashboard/type'
import { DisableDateFields, DisableTimeFields } from 'CommonJS/disableDateFields'
import dayjs from 'dayjs'
import StepDetails from 'Components/Dashboard/StepDetails'
import { checkItemRenderLogic } from 'CommonJS/checkRenderLogic'

function Info() {
  const { id } = useParams()
  const [studentInfo, setStudentInfo] = useState([])
  const [personalInfo, setPersonalInfo] = useState<any>('')
  const [editModal, setEditModal] = useState(false)
  const [addIncidentModal, setAddIncidentModal] = useState(false)
  const [tagModal, setTagModal] = useState(false)
  const [editData, setEditData] = useState<any | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [data_loading, setDataLoading] = useState<boolean>(false)
  const [isViewOnly] = useState<boolean>(false)
  const [incidentFormData, setIncidentFormData] = useState<any>(null)
  const [studentTags, setStudentTags] = useState([])
  const [allTags, setAllTags] = useState([])
  const [isIncidentEdit, setIncidentEdit] = useState(false)
  const [incidentId, setIncidentId] = useState('')
  const [students, setStudents] = useState([])
  const [allStudents, setAllStudents] = useState([])
  const [content, setContent] = useState<any>('')
  const [inputValue, setInputValue] = useState('')
  const [debouncedQuery, setDebouncedQuery] = useState('')
  const [formModal, setFormModal] = useState(false)
  const { data: user } = useContext(UserContext)
  const { dispatch } = useContext(subStepContext)

  const {
    control,
    reset,
    handleSubmit,
    getValues,
    setValue,
    watch,
    setError,
    formState: { isSubmitting }
  } = useForm()

  useEffect(() => {
    getStudentTags()
    getStudentInfo(true)
    getIncidentForm()
  }, [])

  useEffect(() => {
    if (!isIncidentEdit) {
      reset({})
    }
  }, [isIncidentEdit, reset, addIncidentModal])

  useEffect(() => {
    if (!isIncidentEdit) {
      setValue(
        'students',
        allStudents.filter((i: any) => i?.id === Number(id))
      )
    }
  }, [allStudents])

  // Debounce user input
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(inputValue)
    }, 200) // Adjust debounce delay as needed

    return () => {
      clearTimeout(handler)
    }
  }, [inputValue])

  // Fetch students when the debounced query changes
  useEffect(() => {
    if (debouncedQuery.trim().length > 2) {
      getStudents(debouncedQuery)
    }
    if (debouncedQuery.trim().length === 0) setStudents(allStudents)
  }, [debouncedQuery])

  function getStudentInfo(loading: boolean) {
    setLoading(loading)
    http
      .fetch({ path: `students/${id}/studentInfo` })
      .then((res) => {
        if (res.data.success) {
          const { personalInfo, ...remainingObject } = res.data.data
          setPersonalInfo(personalInfo)
          setStudentInfo(remainingObject)
        }
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        toast(error?.message, 'error')
      })
  }

  function getStudentTags() {
    http
      .fetch({ path: `student/${id}/studentInfoTag` })
      .then((res: any) => {
        if (res.data.success) setStudentTags(res.data.tag)
      })
      .catch((error) => {
        toast(error?.message, 'error')
      })
  }

  function getAllTags() {
    http
      .fetch({ path: `studentInfoTag` })
      .then(async (res: any) => {
        if (res.data.success) {
          await setAllTags(res.data.tags)
          await reset({ tags: studentTags })
        }
      })
      .catch((error) => {
        toast(error?.message, 'error')
      })
  }

  const getIncidentForm = () => {
    setDataLoading(true)
    http
      .fetch({ path: 'incidentForm' })
      .then((res: any) => {
        if (res.data.success) {
          setIncidentFormData(res.data.incidentForm)
        }
        setDataLoading(false)
      })
      .catch((error: any) => {
        toast(error, 'error')
        setDataLoading(false)
      })
  }

  const handleModalClose = () => {
    setEditModal(false)
    setEditData(null)
  }

  function getStudents(search?: string) {
    http
      .fetch({ path: `student/${id}/studentList?search=${search ?? ''}` })
      .then((res: any) => {
        if (res.data.success)
          if (search) setStudents(res.data.data)
          else {
            setStudents(res.data.data)
            setAllStudents(res.data.data)
          }
      })
      .catch((error) => {
        toast(error?.message, 'error')
      })
  }

  async function getSubStepFormMeta() {
    try {
      const response = await http.fetch({ path: `student/${id}/meta` }).catch((error) => {
        toast(error?.response?.data.message ?? 'Internal server error.', 'error')
      })
      if (response?.data.success) {
        dispatch({ type: ActionType.SET_META, payload: response.data.message })
      }
    } catch (error) {
      toast('something went wrong please try again', 'error')
    }
  }

  const handleModalOpen = async (data: any, field?: any) => {
    if (data.groupTitle === 'Incident Report') {
      await setAddIncidentModal(true)
      await setEditData(data)
      await getStudents()
      if (field) {
        await setIncidentId(field.id)
        await getIncident(field?.id)
        await setIncidentEdit(true)
      }
    } else if (field?.type === 'form') {
      await setContent(field)
      await getSubStepFormMeta()
      setFormModal(true)
    } else {
      setEditModal(true)
      setEditData(data)
      const defaultValues = {}

      data.fields?.forEach(function (field: any) {
        Object.assign(defaultValues, { [field?.fieldName]: field?.metaValue })
        if (field.type === 'group')
          field.fields?.forEach(function (groupField: any) {
            Object.assign(defaultValues, { [groupField?.fieldName]: groupField?.metaValue })
          })
      })
      reset(defaultValues)
    }
  }

  async function getIncident(incidentId: number) {
    setDataLoading(true)
    http
      .fetch({ path: `incident/${incidentId}` })
      .then((res: any) => {
        if (res.data.success) {
          let meta = {}
          const fields: Array<any> = incidentFormData?.parsedGravityForm?.fields
          for (let i = 0; i < fields?.length; i++) {
            const field = fields[i]
            let value = ''
            if (field?.type === 'signature' || field?.type === 'fileupload') {
              value = res.data.data[field?.adminLabel]
              if (value) {
                Object.assign(meta, { [field?.id?.toString()]: value })
              }
            } else if (field?.type === 'address' || field?.type === 'name') {
              for (let j = 0; j < field?.inputs?.length; j++) {
                const input = field?.inputs[j]
                value = res.data.data[input?.name?.toLowerCase()]
                if (value) {
                  Object.assign(meta, { [input?.id?.toString()]: value })
                }
              }
            } else {
              value = res.data.data[field?.inputName?.toLowerCase()]
              if (value) {
                Object.assign(meta, { [field?.id?.toString()]: value })
              }
            }
          }
          if (res.data.data?.student_id && Array.isArray(res.data.data?.student_id)) {
            meta = { ...meta, ...{ students: res.data.data?.student_id } }
          }
          meta = { ...meta, ...{ primary_user: res.data.data?.primary_user } }
          reset(meta)
        }
        setDataLoading(false)
      })
      .catch((error: any) => {
        toast(error, 'error')
        setDataLoading(false)
      })
  }

  const updateForm = (data: any) => {
    http
      .fetch({ method: 'post', path: `students/${id}/studentInfo`, data: data })
      .then((res) => {
        if (res.data.success) {
          getStudentInfo(false)
          handleModalClose()
          toast(res.data.message, 'success')
        }
      })
      .catch((error) => toast(error?.message, 'error'))
  }

  function submitTags(data: any) {
    const payload = {
      tags: data?.tags?.map((i: any) => {
        return i.id
      })
    }
    http
      .fetch({ method: 'post', path: `student/${id}/studentInfoAddTag`, data: payload })
      .then(async (res: any) => {
        if (res.data.success) {
          toast(res.data?.message, 'success')
          setStudentTags(data?.tags)
          setTagModal(false)
        }
      })
      .catch((error) => {
        toast(error?.message, 'error')
      })
  }

  async function submitIncident(data: any) {
    await setDataLoading(true)
    const meta = {}
    for (let i = 0; i < incidentFormData?.parsedGravityForm?.fields?.length; i++) {
      const field = incidentFormData?.parsedGravityForm?.fields[i]
      const { isRenderConditionalComponent } = checkItemRenderLogic(
        field,
        incidentFormData?.parsedGravityForm?.fields,
        incidentFormData.slug,
        user,
        isViewOnly,
        watch
      )
      if (isRenderConditionalComponent) {
        let value: any = ''
        if (field?.type === 'signature' || field?.type === 'fileupload') {
          value = data[field?.id]
          if (value) {
            Object.assign(meta, { [field?.adminLabel?.toLowerCase()]: value })
          }
        } else if (field?.type === 'address' || field?.type === 'name') {
          const inputs = await field?.inputs?.filter((data: any) => !data?.isHidden)
          for (let j = 0; j < inputs?.length; j++) {
            const input = inputs[j]
            value = data[field?.id?.toString()]
            if (value) {
              value = await value?.filter((data: any) => data)
            }
            if (value) {
              Object.assign(meta, { [input?.name?.toLowerCase()]: value[j] })
            }
          }
        } else if (field?.type === 'date' && value) {
          value = data[field?.id]
          if (value && DisableDateFields?.includes(field?.inputName)) {
            Object.assign(meta, { [field?.inputName.toLowerCase()]: new Date() })
          } else Object.assign(meta, { [field?.inputName.toLowerCase()]: value })
        } else if (field?.type === 'time') {
          value = data[field?.id]
          if (value && DisableTimeFields?.includes(field?.inputName)) {
            Object.assign(meta, { [field?.inputName.toLowerCase()]: dayjs().format('h:mm A') })
          } else Object.assign(meta, { [field?.inputName.toLowerCase()]: value })
        } else {
          value = data[field?.id]
          if (value) {
            Object.assign(meta, { [field?.inputName.toLowerCase()]: value })
          }
        }
      }
    }

    Object.assign(meta, { student_id: data?.students })
    Object.assign(meta, { primary_user: data?.primary_user })
    if (isIncidentEdit) Object.assign(meta, { incident_id: incidentId })

    http
      .fetch({ method: 'post', path: `student/${id}/incidentFormSave`, data: meta })
      .then(async (res: any) => {
        if (res.data.success) {
          handleCloseIncidentForm()
          toast(res.data.message, 'success')
          getStudentInfo(false)
        }
        setDataLoading(false)
      })
      .catch((error) => {
        toast(error?.message, 'error')
        setDataLoading(false)
      })
  }

  const handleCloseIncidentForm = () => {
    setAddIncidentModal(false)
    setIncidentEdit(false)
  }

  if (loading) return <Loading />

  return (
    <div className='flex lg:flex-col w-full'>
      <SideMenu personalInfo={personalInfo} />
      <div className='main-content py-7 px-5 bg-[#e6e4e5] w-full h-[calc(100vh-77px)] overflow-auto md:px-4 lg:h-auto'>
        <h1 className='pt-5 w-full lg:px-4 text-3xl font-bold'>Student Information Summary</h1>
        <div className=' h-1 mt-3 !bg-secondary !font-semibold'></div>
        <div className='mt-5 flex justify-between gap-5 md:flex-col'>
          <div className='flex flex-row gap-3'>
            {studentTags?.map((tag: any, index: number) => {
              return (
                <Chip
                  key={index}
                  sx={{
                    color: '#fff',
                    borderColor: '#2d3e48',
                    '&.MuiChip-outlined': { backgroundColor: '#2d3e48', borderRadius: '5px', textTransform: 'uppercase' }
                  }}
                  label={tag.name}
                  variant='outlined'
                />
              )
            })}
          </div>
          <div
            className='text-center flex items-center gap-2 cursor-pointer'
            onClick={() => {
              getAllTags()
              setTagModal(true)
            }}
          >
            <h3 className='font-medium'>Tag Selector</h3>
            <FaPenToSquare size={14} />
            {/* <p className='font-medium'>(Editable Drop-down Menu)</p> */}
          </div>
        </div>
        <div className='flex w-full flex-wrap gap-5 mt-4'>
          {Object.values(studentInfo).map((item: any, index) => {
            if (item?.data) {
              return (
                <div className={'flex flex-col flex-1 min-w-96'} key={index}>
                  {item?.data?.map((field: any, _index: number) => (
                    <InfoCard
                      key={_index}
                      info={field}
                      handleModalOpen={handleModalOpen}
                      updateForm={updateForm}
                      isInsidentView={!field?.fields?.some((i: any) => i?.type === 'ckeditor')}
                    />
                  ))}
                </div>
              )
            } else {
              return <InfoCard key={index} info={item} handleModalOpen={handleModalOpen} updateForm={updateForm} />
            }
          })}
        </div>
      </div>

      {/* update data modal */}
      <Modal open={editModal} className='flex justify-center items-center' disableAutoFocus={true}>
        <div className='bg-white w-2/4 md:w-3/4 flex flex-col h-auto max-h-[90%]'>
          <div className='flex gap-3 justify-between items-center p-6'>
            <h1 className='text-xl font-semibold'>{editData?.groupTitle}</h1>
            <div onClick={handleModalClose} className='cursor-pointer'>
              <Close />
            </div>
          </div>
          <div className='my-5 overflow-y-auto px-6'>
            <form className='flex flex-col' onSubmit={handleSubmit(updateForm)}>
              {editData?.fields?.map((field: any, index: number) => {
                return (
                  <div key={index} className='flex flex-col mb-5'>
                    <div className='flex gap-3 flex-wrap'>
                      <label className='flex-1 min-w-60'>{field?.label}</label>
                      <EditFields control={control} field={field} setValue={setValue} />
                    </div>
                    {field?.type === 'group' &&
                      field?.fields?.map((groupField: any, _index: number) => {
                        return (
                          <div key={_index} className='flex gap-3 flex-wrap mb-5'>
                            <label className='flex-1 min-w-60'>{groupField?.label}</label>
                            <EditFields control={control} field={groupField} setValue={setValue} />
                          </div>
                        )
                      })}
                  </div>
                )
              })}
              <div className='mt-1 flex justify-center'>
                <button type='submit' className='btn btn-secondary w-min' disabled={isSubmitting}>
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      {/* add incident modal */}
      <Modal open={addIncidentModal} className='flex justify-center items-center' disableAutoFocus={true}>
        <div className='bg-white w-4/6 md:w-5/6 flex flex-col h-full max-h-[95%] relative'>
          <div className='flex gap-3 justify-between items-center p-6'>
            <h1 className='text-xl font-semibold'>{editData?.groupTitle}</h1>
            <div onClick={handleCloseIncidentForm} className='cursor-pointer'>
              <Close />
            </div>
          </div>
          <div className='h-full px-6 flex w-full'>
            {data_loading && <Loading />}
            <form className='h-full w-full' onSubmit={handleSubmit(submitIncident)}>
              <div className='w-full h-[83%] p-2 overflow-auto [&_a]:underline [&_a]:underline-offset-4 md:pl-0 md:h-[calc(100%-175px)]'>
                <div className='flex justify-start md:flex-col mt-5 md:mt-3'>
                  <div className={`flex justify-start w-[30%] md:w-full`}>
                    <h1 className='form-label'>Name of Student/Person who Called</h1>
                  </div>

                  <div className='flex justify-start w-[70%] gap-x-1 md:w-full md:block'>
                    <div className='w-full'>
                      <Controller
                        control={control}
                        name={'primary_user'}
                        rules={isViewOnly ? {} : { required: { value: true, message: 'This field is required.' } }}
                        render={({ field: { ref, value, onChange }, fieldState: { error } }) => {
                          return (
                            <div>
                              <input
                                className='form-input'
                                type={'text'}
                                ref={ref}
                                onChange={onChange}
                                value={value ?? ''}
                                disabled={isViewOnly}
                              />
                              {error && <p className='text-left text-red-500 text-sm'>{error?.message}</p>}
                            </div>
                          )
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className='flex justify-start md:flex-col mt-5 md:mt-3'>
                  <div className={`flex justify-start w-[30%] md:w-full`}>
                    <h1 className='form-label'>Students Involved</h1>
                  </div>

                  <div className='flex justify-start w-[70%] gap-x-1 md:w-full md:block'>
                    <Controller
                      control={control}
                      name={'students'}
                      rules={{ required: { value: false, message: 'This field is required.' } }}
                      render={({ field: { ref, value }, fieldState: { error } }) => {
                        return (
                          <div className='flex w-full flex-col' key={value}>
                            <Autocomplete
                              ref={ref}
                              id='other-students'
                              className='dashboard-icon-home-college w-full pt-2'
                              options={students}
                              multiple
                              isOptionEqualToValue={(option: any, value) => option?.id === value?.id}
                              getOptionLabel={(option: any) => option?.name}
                              value={value ?? undefined}
                              inputValue={inputValue}
                              onChange={(event, selectedOptions) => {
                                setValue('students', selectedOptions)
                                setStudents(allStudents)
                              }}
                              onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue)
                              }}
                              filterOptions={(options) => options} // Disable all client-side filtering
                              renderInput={(params) => <TextField {...params} label='Students Involved' />}
                            />
                            {error && <p className='text-left text-red-500 text-sm'>{error?.message}</p>}
                          </div>
                        )
                      }}
                    />
                  </div>
                </div>
                {incidentFormData?.parsedGravityForm?.fields?.map((field: any, index: number) => {
                  const { isRenderConditionalComponent, isFieldViewOnly } = checkItemRenderLogic(
                    field,
                    incidentFormData?.parsedGravityForm?.fields,
                    incidentFormData.slug,
                    user,
                    isViewOnly,
                    watch
                  )
                  return (
                    <FormItem
                      key={index}
                      index={index}
                      item={field}
                      control={control}
                      setValue={setValue}
                      watch={watch}
                      getValues={getValues}
                      isViewOnly={false}
                      setError={setError}
                      studentId={id ?? ''}
                      handleSubmit={handleSubmit}
                      onNext={() => dispatch({ type: ActionType.NEXT_PAGE })}
                      onPrevious={() => dispatch({ type: ActionType.PREVIOUS_PAGE })}
                      setOpenModal={undefined}
                      isRenderConditionalComponent={isRenderConditionalComponent}
                      isFieldViewOnly={isFieldViewOnly}
                    />
                  )
                })}
              </div>
              {/* submit button */}
              {incidentFormData?.parsedGravityForm?.pagination === null && (
                <div className='flex justify-center bg-white border-t py-4 absolute inset-x-0 bottom-0 md:py-2'>
                  <button className='btn-secondary' type='submit' disabled={data_loading}>
                    {incidentFormData?.parsedGravityForm?.button?.text ? incidentFormData?.parsedGravityForm?.button?.text : 'Submit'}
                  </button>
                </div>
              )}
            </form>
          </div>
        </div>
      </Modal>

      {/* edit tag modal */}
      <Modal open={tagModal} className='flex justify-center items-center' disableAutoFocus={true}>
        <div className='bg-white w-2/5 md:w-3/4 flex flex-col h-auto max-h-[90%]'>
          <div className='flex gap-3 justify-between items-center p-6'>
            <h1 className='text-xl font-semibold'>Student Information Tags</h1>
            <div onClick={() => setTagModal(false)} className='cursor-pointer'>
              <Close />
            </div>
          </div>
          <div className='my-5 overflow-y-auto px-6'>
            <form onSubmit={handleSubmit(submitTags)}>
              <Controller
                control={control}
                name={'tags'}
                rules={{ required: { value: false, message: 'This field is required.' } }}
                render={({ field: { ref, value }, fieldState: { error } }) => {
                  return (
                    <>
                      <Autocomplete
                        ref={ref}
                        id='tags'
                        className='dashboard-icon-home-college w-full pt-2'
                        options={allTags}
                        multiple
                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                        getOptionLabel={(option: any) => option?.name}
                        value={value ?? []}
                        onChange={(event, selectedOptions) => {
                          setValue('tags', selectedOptions)
                        }}
                        filterOptions={createFilterOptions({
                          limit: 100,
                          trim: true
                        })}
                        renderInput={(params) => <TextField {...params} label='Tags' />}
                      />
                      {error && <p className='text-left text-red-500 text-sm'>{error?.message}</p>}
                    </>
                  )
                }}
              />
              <div className='mt-5 flex justify-center'>
                <button type='submit' className='btn btn-secondary w-min'>
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      {formModal && (
        <StepDetails
          setOpenModal={setFormModal}
          wantGravityForm={true}
          formSubStep={{ id: content?.metaValue }}
          formStepInformation={null}
          disabled={false}
          isDownloadPDF={false}
          disabledCallback={() => {
            return false
          }}
          saveInfoCallback={() => {}}
          isViewOnly={true}
          init={null}
          getSubStepFormMeta={function (): Promise<void> {
            throw new Error('Function not implemented.')
          }}
        />
      )}
    </div>
  )
}

export default function StudentInfo() {
  return (
    <SubStepProvider>
      <Info />
    </SubStepProvider>
  )
}
